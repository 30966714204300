/* Copyright 2013 - 2024 Waiterio LLC */
import get from './get.js'

export default function getAppointment(appointmentId) {
  let url = `appointments/${appointmentId}`

  return get({ url }).catch(error => {
    throw new Error(`Couldn't get appointment ${appointmentId} : ${error}`)
  })
}
