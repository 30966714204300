/* Copyright 2013 - 2024 Waiterio LLC */
import { useQuery } from '@tanstack/react-query'
import getClient from '@veterical/client/getClient.js'

export default function useClient(clientId, options) {
  return useQuery({
    queryKey: ['clients', clientId],
    queryFn: () => getClient(clientId),
    ...options,
  })
}
