/* Copyright 2013 - 2024 Waiterio LLC */
import { useQuery } from '@tanstack/react-query'
import getAppointment from '@veterical/client/getAppointment.js'

export default function useAppointment(appointmentId, options) {
  return useQuery({
    queryKey: ['appointments', appointmentId],
    queryFn: () => getAppointment(appointmentId),
    ...options,
  })
}
