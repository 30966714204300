/* Copyright 2013 - 2024 Waiterio LLC */
import delete_ from './delete_.js'

export default function deleteAppointments(parameters) {
  let url = 'appointments'

  url += '?' + new URLSearchParams(parameters).toString()

  return delete_({ url }).catch(error => {
    throw new Error(`Couldn't delete appointments: ${error}`)
  })
}
