/* Copyright 2013 - 2024 Waiterio LLC */
import get from './get.js'

export default function getProcedures(parameters) {
  let url = 'procedures'

  url += '?' + new URLSearchParams(parameters).toString()

  return get({ url }).catch(error => {
    throw new Error(`Couldn't get procedures : ${error}`)
  })
}
