/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import React from 'react'
import { jsx } from '@emotion/react'
import { isBefore, subDays, subHours } from 'date-fns'
import useUser from '@veterical/react/useUser.js'
import getUserId from '../session/getUserId.js'
import RateAppDialog from './RateAppDialog.js'

const isUserCreationTimeOlderThanThreeDays = user => {
  let result = false
  if (user?.creationTime) {
    const dateToCompare = new Date(user.creationTime)
    const threeDaysAgo = subDays(new Date(), 3)
    result = isBefore(dateToCompare, threeDaysAgo)
  }
  return result
}

const isRateAppDialogShowLastTimeOldEnough = user => {
  let result = true
  if (user?.rateAppDialogShowLastTime) {
    const dateToCompare = new Date(user.rateAppDialogShowLastTime)
    const sevenDaysAgo = subDays(new Date(), 7)
    const oneHourAgo = subHours(new Date(), 1)
    result =
      isBefore(dateToCompare, sevenDaysAgo) ||
      isBefore(oneHourAgo, dateToCompare)
  }
  return result
}

const useShowRateAppDialog = () => {
  let showRateAppDialog = false

  let userId
  try {
    userId = getUserId()
  } catch (error) {
    // DO NOTHING ON PURPOSE
  }

  let { data: user } = useUser(userId, { enabled: !!userId })

  if (user) {
    let userOldEnough = isUserCreationTimeOlderThanThreeDays(user)
    let rateAppDialogOldEnough = isRateAppDialogShowLastTimeOldEnough(user)

    showRateAppDialog =
      userOldEnough && rateAppDialogOldEnough && !user.ratedApp
  }

  return showRateAppDialog
}

const DialogsEnforcer = () => {
  let showRateAppDialog = useShowRateAppDialog()

  return showRateAppDialog && <RateAppDialog />
}

export default DialogsEnforcer
