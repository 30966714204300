/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */

/* eslint-disable max-len */
import React, { useEffect, useState } from 'react'
import { css, jsx } from '@emotion/react'
import {
  unstable_useBlocker as useBlocker,
  useNavigate,
  useParams,
} from 'react-router-dom'
import addClient from '@veterical/client/addClient.js'
import deleteAppointments from '@veterical/client/deleteAppointments.js'
import deleteClient from '@veterical/client/deleteClient.js'
import updateClient from '@veterical/client/updateClient.js'
import useClinic from '@veterical/react/useClinic.js'
import useClient from '@veterical/react/useClient.js'
import preferCapitalizedEachWord from '@monorepo/shared/preferCapitalizedEachWord.js'
import { toastError } from '@monorepo/shared/AndroidToast.js'
import uuid from '@monorepo/shared/uuid.js'
import AppBar from '@stiloso/components/AppBar.js'
import AppBarButton from '@stiloso/components/AppBarButton.js'
import AppBarTitle from '@stiloso/components/AppBarTitle.js'
import DeleteDialog from '@stiloso/components/DeleteDialog.js'
import IconDelete from '@stiloso/icons/IconDelete.js'
import card from '@stiloso/styles/card.js'
import clickable from '@stiloso/styles/clickable.js'
import label from '@stiloso/styles/label.js'
import input from '@stiloso/styles/input.js'
import section from '@stiloso/styles/section.js'
import { useTranslation } from '@multilocale/react/index.js'
import SaveChangesPrompt from '../dialogs/SaveChangesPrompt.js'
import Layout from '../components/Layout.js'
import useLoggedInSession from '../session/useLoggedInSession.js'

export const paths = ['/clinics/:clinicId/clients/:clientId']

const ClientPage = () => {
  useLoggedInSession()
  const { clinicId, clientId } = useParams()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const isNewClient = clientId === 'new'
  const { data: clinic } = useClinic(clinicId)
  const { data: client } = useClient(clientId, {
    enabled: clinic && !isNewClient,
  })
  const [edited, setEdited] = useState(false)
  let [_id, setId] = useState(client?._id || uuid())
  let [name, setName] = useState('')
  let [phone, setPhone] = useState('')
  const [deleteConfirmationDialog, setDeleteConfirmationDialog] =
    useState(false)

  const changeName = event => {
    setEdited(true)
    setName(event.target.value)
  }

  const changePhone = event => {
    setEdited(true)
    setPhone(event.target.value)
  }

  let blocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      edited && currentLocation.pathname !== nextLocation.pathname,
  )

  useEffect(() => {
    if (!edited) {
      blocker?.proceed?.()
    }
  }, [edited])

  const addOrUpdateClient = async event => {
    event?.preventDefault?.()

    let lastEditTime = new Date().toISOString()

    try {
      name = preferCapitalizedEachWord(name)
      const client = {
        _id,
        lastEditTime,
        organizationId: clinic.organizationId,
        clinicId: clinic._id,
        name,
        phone,
      }

      if (isNewClient) {
        await addClient({ ...client, creationTime: lastEditTime })
      } else {
        await updateClient(client)
      }

      setEdited(false)
      navigate(`/clinics/${clinicId}/clients`)
    } catch (error) {
      toastError(error)
    }
  }

  let closePrompt = () => {
    blocker.reset()
  }

  let discardChangesPrompt = () => {
    blocker.proceed()
  }

  const showDeleteConfirmationDialog = () => {
    setDeleteConfirmationDialog(true)
  }

  const hideDeleteConfirmationDialog = () => {
    setDeleteConfirmationDialog(false)
  }

  let onDelete = async event => {
    event?.preventDefault?.()

    try {
      if (!isNewClient) {
        await Promise.all([
          deleteClient(clientId),
          deleteAppointments({ clientId }),
        ])
      }

      setEdited(false)
      navigate(`/clinics/${clinicId}/clients`)
    } catch (error) {
      toastError(error)
    }
  }

  let saveChangesPrompt = async () => {
    await addOrUpdateClient()
    blocker.proceed()
  }

  useEffect(() => {
    if (client) {
      setId(client._id)
      setName(client.name)
      setPhone(client.phone)
    }
  }, [client])

  return (
    <Layout title={t(isNewClient ? 'New client' : 'Client')}>
      {blocker?.state === 'blocked' && (
        <SaveChangesPrompt
          close={closePrompt}
          discardChanges={discardChangesPrompt}
          saveChanges={saveChangesPrompt}
        />
      )}
      {deleteConfirmationDialog && (
        <DeleteDialog
          title={t('Delete client')}
          message={t(
            'Do you want to delete client {client_name}’s appointments and related records?',
          ).replace('{client_name}', name)}
          onDelete={onDelete}
          close={hideDeleteConfirmationDialog}
          // loading={deleteClientMutation.isLoading}
        />
      )}
      <form
        onSubmit={addOrUpdateClient}
        css={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}
      >
        <div css={[section]}>
          <AppBar>
            <AppBarTitle>
              {isNewClient ? t('New client') : t('Client') + ' ' + name}
            </AppBarTitle>
            <div css={{ flexGrow: 1 }} />
            {!isNewClient && (
              <AppBarButton
                appearance="secondary"
                Icon={IconDelete}
                label={t('Delete')}
                onClick={showDeleteConfirmationDialog}
              />
            )}
          </AppBar>
          <div css={[card, { margin: '0 16px' }]}>
            <label htmlFor="nameInput" css={label}>
              {t('Name')}
            </label>
            <input
              id="nameInput"
              css={input}
              value={name}
              onChange={changeName}
              autoFocus
              autoComplete="off"
            />
            <label htmlFor="phoneInput" css={label}>
              {t('Phone')}
            </label>
            <input
              id="phoneInput"
              css={input}
              value={phone}
              onChange={changePhone}
            />
          </div>
        </div>
        {(edited || isNewClient) && (
          <button
            type="submit"
            css={[
              clickable,
              {
                position: 'fixed',
                bottom: 0,
                zIndex: 999,
                height: 48,
                border: 0,
                color: 'white',
                background: 'var(--color-green)',
                width: '100%',
                left: 0,
                '@media (min-width: 992px)': {
                  left: 'calc(30vw + 100px)',
                  width: '40vw',
                  borderTopLeftRadius: 16,
                  borderTopRightRadius: 16,
                },
              },
            ]}
          >
            {isNewClient ? t('Add client') : t('Save client')}
          </button>
        )}
        {(edited || isNewClient) && <div css={{ minHeight: 48 }} />}
      </form>
    </Layout>
  )
}

export default ClientPage
