/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import React, { useEffect, Suspense, useState } from 'react'
import { jsx } from '@emotion/react'
import {
  unstable_useBlocker as useBlocker,
  useParams,
  useNavigate,
} from 'react-router-dom'
import useClinic from '@veterical/react/useClinic.js'
import useUpdateClinicMutation from '@veterical/react/useUpdateClinicMutation.js'
import preferCapitalized from '@monorepo/shared/preferCapitalized.js'
import card from '@stiloso/styles/card.js'
import label from '@stiloso/styles/label.js'
import input from '@stiloso/styles/input.js'
import section from '@stiloso/styles/section.js'
import { useTranslation } from '@multilocale/react/index.js'
import Button from '@stiloso/components/Button.js'
import AppBar from '@stiloso/components/AppBar.js'
import Spinner from '@stiloso/components/Spinner.js'
import AppBarTitle from '@stiloso/components/AppBarTitle.js'
import ErrorBoundary from '../components/ErrorBoundary.js'
import Layout from '../components/Layout.js'
import SaveChangesPrompt from '../dialogs/SaveChangesPrompt.js'
import useLoggedInSession from '../session/useLoggedInSession.js'

export const paths = ['/clinics/:clinicName']

const ClinicDetails = () => {
  const { clinicName } = useParams()
  const { t } = useTranslation()
  const { data: clinic } = useClinic(clinicName)
  const navigate = useNavigate()

  const [edited, setEdited] = useState(false)
  let [name, setName] = useState('')

  const { mutate: mutateClinic } = useUpdateClinicMutation()

  const changeName = event => {
    setEdited(true)
    setName(event.target.value)
  }

  let blocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      edited && currentLocation.pathname !== nextLocation.pathname,
  )

  useEffect(() => {
    if (!edited) {
      blocker?.proceed?.()
    }
  }, [edited])

  const saveClinic = event => {
    event?.preventDefault?.()

    name = preferCapitalized(name)

    mutateClinic(
      {
        ...clinic,
        name,
        lastEditTime: new Date().getTime(),
      },
      {
        onSuccess: newClinic => {
          setEdited(false)
          navigate(`/clinics/${newClinic.name}`)
        },
      },
    )
  }

  let closePrompt = () => {
    blocker.reset()
  }

  let discardChangesPrompt = () => {
    blocker.proceed()
  }

  let saveChangesPrompt = async () => {
    await saveClinic()
    blocker.proceed()
  }

  useEffect(() => {
    if (clinic) {
      setName(clinic.name)
    }
  }, [clinic])

  return (
    <>
      {blocker?.state === 'blocked' && (
        <SaveChangesPrompt
          close={closePrompt}
          discardChanges={discardChangesPrompt}
          saveChanges={saveChangesPrompt}
        />
      )}
      {clinic && (
        <div css={card}>
          <form onSubmit={saveClinic} disabled>
            <label htmlFor="nameInput" css={label}>
              {t('Name of your veterinary clinic')}
            </label>
            <input
              id="nameInput"
              css={input}
              value={name}
              onChange={changeName}
              required
              minLength="1"
              autoFocus
            />
            {edited && (
              <Button type="submit" css={{ marginTop: 16 }} fullWidth>
                {t('Save')}
              </Button>
            )}
          </form>
        </div>
      )}
    </>
  )
}

const ClinicPage = () => {
  useLoggedInSession()
  const { t } = useTranslation()

  return (
    <Layout title={t('Settings')}>
      <div css={section}>
        <AppBar>
          <AppBarTitle>{t('Settings')}</AppBarTitle>
        </AppBar>
        <div css={{ padding: 16 }}>
          <ErrorBoundary>
            <Suspense fallback={<Spinner />}>
              <ClinicDetails />
            </Suspense>
          </ErrorBoundary>
        </div>
      </div>
    </Layout>
  )
}

export default ClinicPage
