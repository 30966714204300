/* Copyright 2013 - 2024 Waiterio LLC */
import get from './get.js'

export default function getPatients(parameters) {
  let url = 'patients'

  if (parameters) {
    url += '?' + new URLSearchParams(parameters).toString()
  }

  return get({ url, retries: 3 }).catch(error => {
    throw new Error(`Couldn't get patients : ${error}`)
  })
}
