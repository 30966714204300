/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import React from 'react'
import { Link, useParams } from 'react-router-dom'
import { css, jsx } from '@emotion/react'
import convertStringToColor from '@monorepo/shared/convertStringToColor.js'
import useClinic from '@veterical/react/useClinic.js'
import usePatients from '@veterical/react/usePatients.js'
import card from '@stiloso/styles/card.js'
import clickable from '@stiloso/styles/clickable.js'
import section from '@stiloso/styles/section.js'
import AppBar from '@stiloso/components/AppBar.js'
import AppBarButton from '@stiloso/components/AppBarButton.js'
import AppBarTitle from '@stiloso/components/AppBarTitle.js'
import { useTranslation } from '@multilocale/react/index.js'
import Layout from '../components/Layout.js'
import useLoggedInSession from '../session/useLoggedInSession.js'

let patientCard = css([
  clickable,
  card,
  {
    lineHeight: '48px',
    width: '100%',
    marginTop: 16,
    padding: '0 16',
  },
])

const avatarContainer = css`
  display: flex;
  color: white;
  fontsize: 28px;
  texttransform: capitalize;
  align-items: center;
  justify-content: center;
  height: 32px;
  width: 32px;
  min-width: 32px;
  border-radius: 32px;
  margin: 8px 8px 8px 0;
`

export const paths = ['/clinics/:clinicId/patients']

const PatientsPage = () => {
  useLoggedInSession()
  const { clinicId } = useParams()
  const { t } = useTranslation()
  let { data: clinic } = useClinic(clinicId)
  let { data: patients } = usePatients({ clinicId: clinic._id })

  patients = patients?.sort((a, b) => {
    let sort = 0

    sort = a.name > b.name ? 1 : -1

    if (sort === 0) {
      sort = a._id > b._id ? 1 : -1
    }

    return sort
  })

  patients = patients?.map(patient => ({
    ...patient,
    color: convertStringToColor(patient?._id),
    initial: patient?.name?.charAt(0) || patient?.email?.charAt(0) || '?',
  }))

  return (
    <Layout title={t('Patients')}>
      <div css={section}>
        <AppBar>
          <AppBarTitle>{t('Patients')}</AppBarTitle>
          <div css={{ flexGrow: 1 }} />
          <AppBarButton
            label={t('New patient')}
            to={`/clinics/${clinicId}/patients/new`}
          />
        </AppBar>
        <div
          css={{
            padding: 16,
            display: 'flex',
            flexWrap: 'wrap',
          }}
        >
          {patients?.map(({ _id, color, initial, name }) => (
            <Link
              key={_id}
              css={patientCard}
              to={`/clinics/${clinicId}/patients/${_id}`}
            >
              <div
                css={[
                  avatarContainer,
                  {
                    background: color,
                  },
                ]}
              >
                {initial}
              </div>
              {name}
            </Link>
          ))}
        </div>
      </div>
    </Layout>
  )
}

export default PatientsPage
