/* Copyright 2013 - 2024 Waiterio LLC */
import IS_DEVELOPMENT from './IS_DEVELOPMENT.js'
import IS_STAGING from './IS_STAGING.js'
import IS_TESTING from './IS_TESTING.js'
import IS_BROWSER from './IS_BROWSER.js'
import IS_NODE from './IS_NODE.js'

export default function getVetericalApiUrl() {
  let url = 'https://api.veterical.com'

  if (IS_BROWSER && window.VETERICAL_API_URL === '{{{VETERICAL_API_URL}}}') {
    delete window.VETERICAL_API_URL
  }

  if (IS_NODE && process.env.VETERICAL_API_URL === '{{{VETERICAL_API_URL}}}') {
    delete process.env.VETERICAL_API_URL
  }

  if (IS_BROWSER && window.VETERICAL_API_URL) {
    url = `${window.VETERICAL_API_URL}`
  } else if (IS_NODE && process.env.VETERICAL_API_URL) {
    url = process.env.VETERICAL_API_URL
  } else if (IS_STAGING) {
    url = 'https://api.veterical-staging.com'
  } else if (IS_DEVELOPMENT || IS_TESTING) {
    // url = 'https://api.veterical-staging.com';
    // url = 'http://192.168.1.13:24300'
    url = 'http://localhost:24300'
  }

  // if (IS_STAGING || IS_DEVELOPMENT) {
  //   console.debug('Veterical.url = ' + url)
  // }

  return url
}
