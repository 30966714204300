/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import React from 'react'
import { jsx } from '@emotion/react'
import { useTranslation } from '@multilocale/react/index.js'
import Drawer from '@stiloso/components/Drawer.js'
import DrawerButton from '@stiloso/components/DrawerButton.js'
import DrawerTitle from '@stiloso/components/DrawerTitle.js'
import Tabs from '@stiloso/components/Tabs.js'
import Tab from '@stiloso/components/Tab.js'
import IconPowerOff from '@stiloso/icons/IconPowerOff.js'
import IconUser from '@stiloso/icons/IconUser.js'
import { Link, useParams } from 'react-router-dom'
import useClinics from '@veterical/react/useClinics.js'
import useUser from '@veterical/react/useUser.js'
import IconFolders from '@stiloso/icons/IconFolders.js'
import IconSettings from '@stiloso/icons/IconSettings.js'
import IconAlarm from '@stiloso/icons/IconAlarm.js'
import IconCalendar from '@stiloso/icons/IconCalendar.js'
import IconPaw from '@stiloso/icons/IconPaw.js'
import IconSearchPerson from '@stiloso/icons/IconSearchPerson.js'
import IconSyringeBandAid from '@stiloso/icons/IconSyringeBandAid.js'
import IconUsers from '@stiloso/icons/IconUsers.js'
import getUserId from '../session/getUserId.js'

const LayoutDesktop = ({ children }) => {
  const { t } = useTranslation()
  let { data: clinics } = useClinics()
  const { data: user } = useUser(getUserId())
  let { clinicId } = useParams()

  clinics = clinics?.sort((a, b) => (a.creationTime > b.creationTime ? 1 : -1))

  let pathname = ''

  if (typeof window !== 'undefined') {
    pathname = window.location.pathname
  }

  clinicId = clinicId || clinics?.[0]?._id

  let fullName = ''

  if (user.firstName && user.lastName) {
    fullName = `${user.firstName} ${user.lastName}`
  }

  const drawerButtons = [
    clinics.length > 1 && {
      label: 'Clinics',
      to: '/clinics',
      Icon: IconFolders,
    },
    {
      label: 'Calendar',
      to: '/clinics/{clinicId}/calendar',
      Icon: IconCalendar,
    },
    {
      label: 'Appointments',
      to: '/clinics/{clinicId}/appointments',
      Icon: IconAlarm,
    },
    {
      label: 'Clients',
      to: '/clinics/{clinicId}/clients',
      Icon: IconSearchPerson,
    },
    {
      label: 'Patients',
      to: '/clinics/{clinicId}/patients',
      Icon: IconPaw,
    },
    {
      label: 'Procedures',
      to: '/clinics/{clinicId}/procedures',
      Icon: IconSyringeBandAid,
    },
    {
      label: 'Team',
      to: '/team',
      Icon: IconUsers,
    },
    {
      label: 'Settings',
      to: '/clinics/{clinicId}',
      Icon: IconSettings,
    },
  ].filter(_ => _)

  let showNavbar = clinics.length > 1

  return (
    <div
      css={{
        display: 'grid',
        height: '100%',
        gridTemplateRows: showNavbar ? '48px auto' : null,
        gridTemplateColumns: '200px auto',
        gridTemplateAreas: showNavbar
          ? `
          'navbar navbar'
          'drawer children'
        `
          : `
          'drawer children'
        `,
      }}
    >
      {clinics.length > 1 && (
        <Tabs
          styles={{
            gridArea: 'navbar',
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'row',
            background: '#35363A',
            width: '100%',
            position: 'fixed',
            border: 0,
            height: 48,
            top: 0,
          }}
        >
          {clinics.map(clinic => {
            let selected = clinicId === clinic._id

            return (
              <Link
                key={clinic._id}
                to={`/clinics/${clinic._id}/calendar`}
                css={{
                  flexGrow: 1,
                  maxWidth: 200,
                }}
              >
                <Tab
                  selected={selected}
                  styles={[
                    {
                      color: '#909192',
                      lineHeight: '48px',
                      textTransform: 'uppercase',
                    },
                    selected && {
                      '&': {
                        boxShadow:
                          '0px -4px 0px var(--color-secondary) inset !important',
                        color: 'var(--color-secondary) !important',
                        fontWeight: 'bold',
                      },
                    },
                  ]}
                >
                  {clinic._id}
                </Tab>
              </Link>
            )
          })}
        </Tabs>
      )}
      <Drawer top={clinics.length > 1 ? 48 : 0}>
        <DrawerTitle>{t('Veterical')}</DrawerTitle>
        {drawerButtons.map(({ label, to, Icon }) => (
          <DrawerButton
            key={to}
            label={t(label)}
            to={to.replace('{clinicId}', clinicId)}
            Icon={Icon}
            selected={pathname.endsWith(to.replace('{clinicId}', clinicId))}
          />
        ))}
        <div css={{ flexGrow: 1 }} />
        <DrawerButton
          key="/account"
          label={fullName || t('Account')}
          to="/account"
          Icon={IconUser}
          avatarUrl={user.avatarUrl}
        />
        <DrawerButton
          key="/logout"
          label={t('Logout')}
          to="/logout"
          Icon={IconPowerOff}
        />
      </Drawer>
      <div
        css={{
          gridArea: 'children',
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          width: '100%',
          background: 'var(--color-background)',
          overflowX: 'hidden',
        }}
      >
        {children}
      </div>
    </div>
  )
}

export default LayoutDesktop
