/* Copyright 2013 - 2024 Waiterio LLC */
import get from './get.js'

export default function getClinic(clinicIdOrName, organizationId) {
  let url = `clinics/${clinicIdOrName}`

  if (organizationId) {
    url += `?organizationId=${organizationId}`
  }

  return get({ url }).catch(error => {
    throw new Error(`Couldn't get clinic ${clinicIdOrName} : ${error}`)
  })
}
