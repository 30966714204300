/* Copyright 2013 - 2024 Waiterio LLC */
/* eslint-disable camelcase */
import fetch from '@monorepo/fetch/index.js'
import getVetericalApiUrl from '@monorepo/env/getVetericalApiUrl.js'

export default ({
  email,
  password,
  firstName,
  lastName,
  language,
  gclid,
  referrer,
  utm_campaign,
  utm_device,
  utm_source,
  utm_term,
}) =>
  fetch({
    url: getVetericalApiUrl() + '/api/signup',
    method: 'POST',
    body: {
      firstName,
      lastName,
      language,
      gclid,
      referrer,
      utm_campaign,
      utm_device,
      utm_source,
      utm_term,
    },
    headers: {
      Authorization: `Basic ${btoa(email + ':' + password)}`,
    },
  })
