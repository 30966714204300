/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import { useTranslation } from '@multilocale/react/index.js'
import IconBird from '@stiloso/icons/IconBird.js'
import IconCat from '@stiloso/icons/IconCat.js'
import IconDog from '@stiloso/icons/IconDog.js'
import IconHorse from '@stiloso/icons/IconHorse.js'
import IconLizard from '@stiloso/icons/IconLizard.js'
import IconRodent from '@stiloso/icons/IconRodent.js'
import IconSheep from '@stiloso/icons/IconSheep.js'
import IconQuestionMark from '@stiloso/icons/IconQuestionMark.js'
import clickable from '@stiloso/styles/clickable.js'
import label from '@stiloso/styles/label.js'
import species from '../species.js'

const IconSpecies = ({ species }) => {
  switch (species) {
    case 'bird':
      return <IconBird />
    case 'cat':
      return <IconCat />
    case 'dog':
      return <IconDog />
    case 'horse':
      return <IconHorse />
    case 'livestock':
      return <IconSheep />
    case 'reptile':
      return <IconLizard />
    case 'rodent':
      return <IconRodent />
    default:
      return <IconQuestionMark />
  }
}

const SpeciesButton = ({ selected, species, onClick }) => {
  let { t } = useTranslation()
  return (
    <div
      css={[
        clickable,
        {
          display: 'flex',
          flexDirection: 'column',
          justifyItems: 'center',
          alignItems: 'center',
          minWidth: 96,
          padding: 8,
          background: selected ? 'var(--color-primary)' : 'white',
          color: selected ? 'white' : 'inherit',
          fill: selected ? 'white' : 'inherit',
          opacity: selected ? 1 : 0.75,
          borderRadius: 8,
        },
      ]}
      onClick={onClick}
    >
      <IconSpecies species={species} />
      {t(species)}
    </div>
  )
}

const SpeciesButtons = ({ currentSpecies, onSelectSpecies }) => {
  let { t } = useTranslation()

  return (
    <>
      <label htmlFor="patientSpeciesInput" css={label}>
        {t('Patient species')}
      </label>
      <div css={{ display: 'flex', flexWrap: 'wrap' }}>
        {species.map(species_ => (
          <SpeciesButton
            key={species_}
            selected={species_ === currentSpecies}
            species={species_}
            onClick={() => {
              onSelectSpecies(species_)
            }}
          />
        ))}
      </div>
    </>
  )
}

export default SpeciesButtons
