/* Copyright 2013 - 2024 Waiterio LLC */
import { useQuery } from '@tanstack/react-query'
import getAppointments from '@veterical/client/getAppointments.js'

export default function useAppointments(parameters, options) {
  return useQuery({
    queryKey: ['appointments', { ...parameters }],
    queryFn: () => getAppointments(parameters),
    ...options,
  })
}
