/* Copyright 2013 - 2024 Waiterio LLC */
import get from './get.js'

export default function getProcedure(procedureId) {
  let url = `procedures/${procedureId}`

  return get({ url }).catch(error => {
    throw new Error(`Couldn't get procedure ${procedureId} : ${error}`)
  })
}
