/* Copyright 2013 - 2024 Waiterio LLC */
import localStorage from 'localStorage'
import {
  setAccessTokenForVetericalClient,
  setAccessTokenCallbackForVetericalClient,
} from '@veterical/client/accessToken.js'
import { setRefreshTokenForVetericalClient } from '@veterical/client/refreshToken.js'
import IS_BROWSER from '@monorepo/env/IS_BROWSER.js'
import clearCookies from '@monorepo/shared/clearCookies.js'

export default function clearSession() {
  if (IS_BROWSER) {
    localStorage.clear()

    setAccessTokenCallbackForVetericalClient(null)

    setAccessTokenForVetericalClient(null)
    setRefreshTokenForVetericalClient(null)

    clearCookies()
  }
}
