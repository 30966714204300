/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import React, { useEffect, useMemo, useState } from 'react'
import { css, jsx } from '@emotion/react'
import { useTranslation } from '@multilocale/react/index.js'
import clickable from '@stiloso/styles/clickable.js'
import input from '@stiloso/styles/input.js'
import label from '@stiloso/styles/label.js'
import breeds from '../breeds.js'

const DropdownBreeds = ({ currentBreed, species, onSelectBreed }) => {
  const { t } = useTranslation()

  let breeds2translation = useMemo(
    () =>
      breeds[species].slice(1).reduce((breeds2translation, breed) => {
        breeds2translation[breed] = t(breed)

        return breeds2translation
      }, {}),
    [species],
  )

  let breedsSortedByTranslation = ['other'].concat(
    Object.keys(breeds2translation).sort((a, b) =>
      breeds2translation[a].localeCompare(breeds2translation[b]),
    ),
  )

  breeds2translation = { other: t('other'), ...breeds2translation }

  let [breedName, setBreedName] = useState('')
  let [filteredBreeds, setFilteredBreeds] = useState([])
  let [focused, setFocused] = useState(false)

  const changeBreedName = event => {
    setBreedName(event.target.value)
  }

  let onSelectBreed_ = breed => {
    onSelectBreed(breed)
    setFilteredBreeds([])
  }

  let onFocus = () => {
    setFocused(true)
  }

  let onBlur = () => {
    // delay of 100 millisecons to allow onClick to be triggered
    setTimeout(() => {
      setFocused(false)
      setBreedName('')
    }, 300)
  }

  useEffect(() => {
    if (focused && breedName?.length === 0) {
      setFilteredBreeds(breedsSortedByTranslation)
    } else if (breedName?.length >= 1) {
      setFilteredBreeds(
        breedsSortedByTranslation.filter(
          breed =>
            breed.toLowerCase().includes(breedName.toLowerCase()) ||
            breeds2translation[breed]
              .toLowerCase()
              .includes(breedName.toLowerCase()),
        ),
      )
    }
  }, [breedName, focused])

  return (
    breedsSortedByTranslation && (
      <>
        <label htmlFor="patientBreedInput" css={label}>
          {t('Patient breed')}
        </label>
        <input
          id="breedNameInput"
          css={[
            input,
            {
              '::placeholder': {
                color:
                  !focused && currentBreed !== 'other'
                    ? 'var(--color-base)'
                    : 'inherit',
              },
            },
          ]}
          value={breedName}
          onChange={changeBreedName}
          autoComplete="off"
          placeholder={focused ? t('search a breed') : t(currentBreed)}
          onFocus={onFocus}
          onBlur={onBlur}
        />

        {focused && (
          <div css={{ position: 'relative' }}>
            <div
              css={{
                position: 'absolute',
                background: 'white',
                width: '100%',
              }}
            >
              {filteredBreeds.map(breed => (
                <div
                  key={breed}
                  css={[
                    clickable,
                    {
                      border: '1px solid #D9E0ED',
                      padding: '12px 8px',
                    },
                  ]}
                  onClick={() => onSelectBreed_(breed)}
                >
                  {breeds2translation[breed]}
                </div>
              ))}
            </div>
          </div>
        )}
      </>
    )
  )
}

export default DropdownBreeds
