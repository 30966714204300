/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */

/* eslint-disable max-len */
import React, { useEffect, useState } from 'react'
import { css, jsx } from '@emotion/react'
import {
  unstable_useBlocker as useBlocker,
  useNavigate,
  useParams,
} from 'react-router-dom'
import addProcedure from '@veterical/client/addProcedure.js'
import deleteProcedure from '@veterical/client/deleteProcedure.js'
import updateProcedure from '@veterical/client/updateProcedure.js'
import useClinic from '@veterical/react/useClinic.js'
import useProcedure from '@veterical/react/useProcedure.js'
import preferCapitalized from '@monorepo/shared/preferCapitalized.js'
import { toastError } from '@monorepo/shared/AndroidToast.js'
import uuid from '@monorepo/shared/uuid.js'
import AppBar from '@stiloso/components/AppBar.js'
import AppBarButton from '@stiloso/components/AppBarButton.js'
import AppBarTitle from '@stiloso/components/AppBarTitle.js'
import IconDelete from '@stiloso/icons/IconDelete.js'
import card from '@stiloso/styles/card.js'
import label from '@stiloso/styles/label.js'
import input from '@stiloso/styles/input.js'
import Button from '@stiloso/components/Button.js'
import section from '@stiloso/styles/section.js'
import { useTranslation } from '@multilocale/react/index.js'
import SaveChangesPrompt from '../dialogs/SaveChangesPrompt.js'
import Layout from '../components/Layout.js'
import useLoggedInSession from '../session/useLoggedInSession.js'

export const paths = ['/clinics/:clinicId/procedures/:procedureId']

const ProcedurePage = () => {
  useLoggedInSession()
  const { clinicId, procedureId } = useParams()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const isNewProcedure = procedureId === 'new'
  const { data: clinic } = useClinic(clinicId)
  const { data: procedure } = useProcedure(procedureId, {
    enabled: clinic && !isNewProcedure,
  })
  const [edited, setEdited] = useState(false)
  let [_id, setId] = useState(procedure?._id || uuid())
  let [name, setName] = useState('')
  let [price, setPrice] = useState()

  const changeName = event => {
    setEdited(true)
    setName(event.target.value)
  }

  const changePrice = event => {
    setEdited(true)
    setPrice(event.target.value)
  }

  let blocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      edited && currentLocation.pathname !== nextLocation.pathname,
  )

  useEffect(() => {
    if (!edited) {
      blocker?.proceed?.()
    }
  }, [edited])

  const addOrUpdateProcedure = async event => {
    event?.preventDefault?.()

    let lastEditTime = new Date().toISOString()

    name = preferCapitalized(name)

    try {
      const procedure = {
        _id,
        lastEditTime,
        organizationId: clinic.organizationId,
        clinicId: clinic._id,
        name,
        price,
      }

      if (isNewProcedure) {
        await addProcedure({ ...procedure, creationTime: lastEditTime })
      } else {
        await updateProcedure(procedure)
      }

      setEdited(false)
      navigate(`/clinics/${clinicId}/procedures`)
    } catch (error) {
      toastError(error)
    }
  }

  let closePrompt = () => {
    blocker.reset()
  }

  let discardChangesPrompt = () => {
    blocker.proceed()
  }

  let onDelete = async event => {
    event?.preventDefault?.()

    try {
      if (!isNewProcedure) {
        await deleteProcedure(procedureId)
      }

      setEdited(false)
      navigate(`/clinics/${clinicId}/procedures`)
    } catch (error) {
      toastError(error)
    }
  }

  let saveChangesPrompt = async () => {
    await addOrUpdateProcedure()
    blocker.proceed()
  }

  useEffect(() => {
    if (procedure) {
      setId(procedure._id)
      setName(procedure.name)
      setPrice(procedure.price)
    }
  }, [procedure])

  return (
    <Layout title={t(isNewProcedure ? 'New procedure' : 'Procedure')}>
      {blocker?.state === 'blocked' && (
        <SaveChangesPrompt
          close={closePrompt}
          discardChanges={discardChangesPrompt}
          saveChanges={saveChangesPrompt}
        />
      )}
      <div css={section}>
        <AppBar>
          <AppBarTitle>
            {isNewProcedure ? t('New procedure') : t('Procedure')}
          </AppBarTitle>
          <div css={{ flexGrow: 1 }} />
          {!isNewProcedure && (
            <AppBarButton
              appearance="secondary"
              Icon={IconDelete}
              label={t('Delete')}
              onClick={onDelete}
            />
          )}
        </AppBar>
        <form
          onSubmit={addOrUpdateProcedure}
          css={[card, { margin: '0 16px' }]}
        >
          <label htmlFor="nameInput" css={label}>
            {t('Name')}
          </label>
          <input
            id="nameInput"
            css={input}
            value={name}
            onChange={changeName}
            autoFocus
            autoComplete="off"
          />
          <label htmlFor="priceInput" css={label}>
            {t('Price')}
          </label>
          <input
            id="priceInput"
            css={input}
            value={price}
            placeholder="0"
            min="0"
            type="number"
            onChange={changePrice}
          />
          {(edited || isNewProcedure) && (
            <Button type="submit" css={{ marginTop: 16 }} fullWidth>
              {isNewProcedure ? t('Add procedure') : t('Save procedure')}
            </Button>
          )}
        </form>
      </div>
    </Layout>
  )
}

export default ProcedurePage
