/* Copyright 2013 - 2024 Waiterio LLC */
import fetch from '@monorepo/fetch/index.js'
import getApiUrl from '@veterical/shared/getApiUrl.js'

const postChangePassword = ({ newPassword, forgotPasswordToken }) =>
  fetch({
    url: getApiUrl() + '/api/change-password',
    method: 'POST',
    body: {
      newPassword,
      forgotPasswordToken,
    },
  })

export default postChangePassword
