/* Copyright 2013 - 2024 Waiterio LLC */
import get from './get.js'

export default function getPatient(patientId) {
  let url = `patients/${patientId}`

  return get({ url }).catch(error => {
    throw new Error(`Couldn't get patient ${patientId} : ${error}`)
  })
}
