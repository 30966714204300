/* Copyright 2013 - 2024 Waiterio LLC */
/* eslint-disable max-len */
import React from 'react'

const IconHorse = props => {
  const { className, width = 24, height = 24, onClick, style } = props
  const dataTestId = props['data-testid'] || 'icon-horse'

  return (
    <svg
      className={className}
      style={style}
      width={width}
      height={height}
      viewBox="0 0 512 512"
      onClick={onClick}
      data-testid={dataTestId}
    >
      <path d="M371.729,129.381c29.32-67.012,5.924-128.456-8.26-129.373c-14.184-0.923-60.556,77.069-60.556,77.069h-93.59 c0,0-46.379-77.986-60.556-77.069c-14.185,0.909-37.58,62.36-8.26,129.373c19.269,44.042-22.955,89.77-14.604,134.884 c8.967,48.512,58.647,154.138,58.647,165.154c0,11.009-8.26,49.548,11.009,66.061C214.827,512,256.114,512,256.114,512 s41.294,0,60.563-16.52c19.269-16.514,11.009-55.052,11.009-66.061c0-11.016,53.464-126.868,58.646-165.154 C393.607,210.646,352.46,173.423,371.729,129.381z M226.528,478.966c-4.56,0-8.26-6.778-8.26-15.143 c0-8.358,3.7-15.135,8.26-15.135c4.554,0,8.254,6.778,8.254,15.135C234.782,472.189,231.082,478.966,226.528,478.966z  M255.695,382.621c-14.129-50.471-28.257-183.851-34.789-251.38c11.386,13.408,28.564,24.438,45.805,28.418l-2.756-33.026 l26.026,9.904C283.238,205.527,269.473,333.438,255.695,382.621z M285.707,478.966c-4.56,0-8.26-6.778-8.26-15.143 c0-8.358,3.7-15.135,8.26-15.135c4.561,0,8.261,6.778,8.261,15.135C293.968,472.189,290.268,478.966,285.707,478.966z  M293.855,95.157l-2.252-4.315h2.63C294.233,90.842,294.107,92.374,293.855,95.157z" />
    </svg>
  )
}

export default IconHorse
