/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import React from 'react'
import { jsx, css } from '@emotion/react'
import { useParams } from 'react-router-dom'
import { useTranslation } from '@multilocale/react/index.js'
import NavBar from '@stiloso/components/NavBar.js'
import NavBarFiller from '@stiloso/components/NavBarFiller.js'
import NavBarSmartphoneMenu from '@stiloso/components/NavBarSmartphoneMenu.js'
import NavBarSmartphoneLink from '@stiloso/components/NavBarSmartphoneLink.js'
import useClinics from '@veterical/react/useClinics.js'

const LayoutSmartphone = ({ children, title }) => {
  const { t } = useTranslation()
  let { data: clinics } = useClinics()
  let { clinicId } = useParams()

  clinics = clinics?.sort((a, b) => (a.creationTime > b.creationTime ? 1 : -1))

  clinicId = clinicId || clinics?.[0]?._id

  const drawerButtons = [
    clinics.length > 1 && {
      label: 'Clinics',
      to: '/clinics',
    },
    {
      label: 'Calendar',
      to: '/clinics/{clinicId}/calendar',
    },
    {
      label: 'Appointments',
      to: '/clinics/{clinicId}/appointments',
    },
    {
      label: 'Clients',
      to: '/clinics/{clinicId}/clients',
    },
    {
      label: 'Patients',
      to: '/clinics/{clinicId}/patients',
    },
    {
      label: 'Procedures',
      to: '/clinics/{clinicId}/procedures',
    },
    {
      label: 'Team',
      to: '/team',
    },
    {
      label: 'Settings',
      to: '/clinics/{clinicId}',
    },
    {
      label: 'Account',
      to: '/account',
    },
  ].filter(_ => _)

  return (
    <div
      css={{
        display: 'grid',
        height: '100%',
        gridTemplateRows: '64px auto',
        gridTemplateAreas: `
          'navbar'
          'children'
        `,
      }}
    >
      <div css={{ gridArea: 'navbar' }}>
        <NavBar>
          <a
            aria-label={t('Veterical')}
            href="/"
            css={{ color: 'white', padding: 16 }}
          >
            {title || t('Veterical')}
          </a>
          <NavBarFiller />
          <NavBarSmartphoneMenu>
            {drawerButtons.map(({ label, to }) => (
              <NavBarSmartphoneLink
                key={to}
                label={t(label)}
                href={to.replace('{clinicId}', clinicId)}
              />
            ))}
          </NavBarSmartphoneMenu>
        </NavBar>
      </div>
      <div
        css={{
          gridArea: 'children',
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          width: '100%',
          background: 'var(--color-background)',
          overflowX: 'hidden',
        }}
      >
        {children}
      </div>
    </div>
  )
}

export default LayoutSmartphone
