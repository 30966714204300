/* Copyright 2013 - 2024 Waiterio LLC */
import { useMutation, useQueryClient } from '@tanstack/react-query'
import addInvite from '@veterical/client/addInvite.js'

export default function useInviteMutation() {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: invite => addInvite(invite),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['invites'] })
      queryClient.invalidateQueries({ queryKey: ['users'] })
      queryClient.invalidateQueries({ queryKey: ['roles'] })
    },
  })
}
