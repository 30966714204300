/* Copyright 2013 - 2024 Waiterio LLC */
import { css } from '@emotion/react'

export default css`
  label: input;

  width: 100%;
  box-shadow: inset 0px 0px 0px 1px #d8e0ed;
  border-radius: 4px;
  padding: 12px;
  color: #6a768a;
  line-height: 24px;

  ::placeholder {
    // TODO color
  }

  &:not(:disabled):focus-visible {
    box-shadow: inset 0px 0px 0px 2px var(--color-primary-lightest);
    cursor: text;
  }

  &:not(:disabled):focus-visible-within {
    box-shadow: inset 0px 0px 0px 2px var(--color-primary-lightest);
    cursor: text;
  }
`
