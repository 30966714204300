/* Copyright 2013 - 2024 Waiterio LLC */
import React from 'react'
import { Navigate, useSearchParams } from 'react-router-dom'
import useClinics from '@veterical/react/useClinics.js'
import isLoggedInSession from '../session/isLoggedInSession.js'

export const paths = ['/']

const HomeRedirect = () => {
  const [searchParams] = useSearchParams()
  const { data: clinics } = useClinics({ enabled: isLoggedInSession() })

  if (isLoggedInSession()) {
    if (clinics.length === 1) {
      return <Navigate exact to={`/clinics/${clinics[0]._id}/appointments`} />
    } else {
      return <Navigate exact to="/clinics" />
    }
  } else {
    return <Navigate exact to={`/signup?${searchParams}`} />
  }
}

export default HomeRedirect
