/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import dNone from '@stiloso/styles/bootstrap/dNone.js'
import dLgBlock from '@stiloso/styles/bootstrap/dLgBlock.js'
import dLgNone from '@stiloso/styles/bootstrap/dLgNone.js'
import LayoutDesktop from './LayoutDesktop.js'
import LayoutSmartphone from './LayoutSmartphone.js'

const Layout = ({ children, title }) => (
  <>
    <div css={dLgNone}>
      <LayoutSmartphone title={title}>{children}</LayoutSmartphone>
    </div>
    <div css={[dNone, dLgBlock]}>
      <LayoutDesktop>{children}</LayoutDesktop>
    </div>
  </>
)

export default Layout
