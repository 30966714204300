/* Copyright 2013 - 2024 Waiterio LLC */
import { useQuery } from '@tanstack/react-query'
import getProcedures from '@veterical/client/getProcedures.js'

export default function useProcedures(parameters) {
  return useQuery({
    queryKey: ['appointments', { ...parameters }],
    queryFn: () => getProcedures(parameters),
  })
}
