/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import React, { useEffect, useState } from 'react'
import { pipe } from 'ramda'
import {
  addMinutes,
  format,
  formatISO,
  getDayOfYear,
  getHours,
  getMinutes,
  getMonth,
  getYear,
  isBefore,
  parse,
  parseISO,
  setDayOfYear,
  setHours,
  setMinutes,
  setMonth,
  setYear,
  startOfToday,
} from 'date-fns'
import { css, jsx } from '@emotion/react'
import {
  unstable_useBlocker as useBlocker,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom'
import addAppointment from '@veterical/client/addAppointment.js'
import addClient from '@veterical/client/addClient.js'
import addPatient from '@veterical/client/addPatient.js'
import deleteAppointment from '@veterical/client/deleteAppointment.js'
import updateAppointment from '@veterical/client/updateAppointment.js'
import updateClient from '@veterical/client/updateClient.js'
import updatePatient from '@veterical/client/updatePatient.js'
import useClinic from '@veterical/react/useClinic.js'
import useAppointment from '@veterical/react/useAppointment.js'
import useClient from '@veterical/react/useClient.js'
import useClients from '@veterical/react/useClients.js'
import usePatient from '@veterical/react/usePatient.js'
import usePatients from '@veterical/react/usePatients.js'
import useProcedures from '@veterical/react/useProcedures.js'
import preferCapitalizedEachWord from '@monorepo/shared/preferCapitalizedEachWord.js'
import { toast, toastError } from '@monorepo/shared/AndroidToast.js'
import uuid from '@monorepo/shared/uuid.js'
import AppBar from '@stiloso/components/AppBar.js'
import AppBarButton from '@stiloso/components/AppBarButton.js'
import AppBarTitle from '@stiloso/components/AppBarTitle.js'
import IconDelete from '@stiloso/icons/IconDelete.js'
import card from '@stiloso/styles/card.js'
import clickable from '@stiloso/styles/clickable.js'
import label from '@stiloso/styles/label.js'
import input from '@stiloso/styles/input.js'
import section from '@stiloso/styles/section.js'
import { useTranslation } from '@multilocale/react/index.js'
import DropdownBreeds from '../components/DropdownBreeds.js'
import SpeciesButtons from '../components/SpeciesButtons.js'
import Layout from '../components/Layout.js'
import SaveChangesPrompt from '../dialogs/SaveChangesPrompt.js'
import useLoggedInSession from '../session/useLoggedInSession.js'

export const paths = ['/clinics/:clinicId/appointments/:appointmentId']

const dateInputFormat = 'yyyy-MM-dd'
const timeInputFormat = 'HH:mm'
const defaultAppointmentLengthMinutes = 30

const DropdownClientName = ({
  clinicId,
  clientName,
  clientId,
  onSelectClient,
}) => {
  let { data: clients } = useClients(
    {
      clinicId,
    },
    {
      enabled: !!clinicId,
    },
  )

  let [filteredClients, setFilteredClients] = useState([])

  clientName = clientName.toLowerCase()

  let onSelectClient_ = client => {
    onSelectClient(client)
    setFilteredClients([])
  }

  useEffect(() => {
    if (!clientId && clientName?.length >= 2) {
      setFilteredClients(
        clients.filter(client =>
          client.name.toLowerCase().includes(clientName),
        ),
      )
    }
  }, [clientName])

  useEffect(() => {
    const handleClickOutside = event => {
      if (clients.length) {
        setFilteredClients([])
      }
    }

    document.addEventListener('click', handleClickOutside)

    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [])

  return (
    clients && (
      <div css={{ position: 'relative' }}>
        <div
          css={{
            position: 'absolute',
            background: 'white',
            width: '100%',
          }}
        >
          {filteredClients.map(client => (
            <div
              key={client._id}
              css={[
                clickable,
                {
                  border: '1px solid #D9E0ED',
                  padding: '12px 8px',
                },
              ]}
              onClick={() => onSelectClient_(client)}
            >
              {client.name}&nbsp;&nbsp;{client.phone}
            </div>
          ))}
        </div>
      </div>
    )
  )
}

const DropdownClientPhone = ({
  clinicId,
  clientPhone,
  clientId,
  onSelectClient,
}) => {
  let { data: clients } = useClients(
    {
      clinicId,
    },
    {
      enabled: !!clinicId,
    },
  )

  let [filteredClients, setFilteredClients] = useState([])

  clientPhone = clientPhone.toLowerCase()

  let onSelectClient_ = client => {
    onSelectClient(client)
    setFilteredClients([])
  }

  useEffect(() => {
    if (!clientId && clientPhone?.length >= 2) {
      setFilteredClients(
        clients.filter(client =>
          client.phone.toLowerCase().includes(clientPhone),
        ),
      )
    }
  }, [clientPhone])

  useEffect(() => {
    const handleClickOutside = event => {
      if (clients.length) {
        setFilteredClients([])
      }
    }

    document.addEventListener('click', handleClickOutside)

    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [])

  return (
    clients && (
      <div css={{ position: 'relative' }}>
        <div
          css={{
            position: 'absolute',
            background: 'white',
            width: '100%',
          }}
        >
          {filteredClients.map(client => (
            <div
              key={client._id}
              css={[
                clickable,
                { border: '1px solid #D9E0ED', padding: '12px 8px' },
              ]}
              onClick={() => onSelectClient_(client)}
            >
              {client.phone}&nbsp;&nbsp;{client.name}
            </div>
          ))}
        </div>
      </div>
    )
  )
}

const DropdownPatientName = ({
  clinicId,
  patientName,
  patientId,
  onSelectPatient,
}) => {
  let { data: patients } = usePatients(
    {
      clinicId,
    },
    {
      enabled: !!clinicId,
    },
  )

  let [filteredPatients, setFilteredPatients] = useState([])

  patientName = patientName.toLowerCase()

  let onSelectPatient_ = patient => {
    onSelectPatient(patient)
    setFilteredPatients([])
  }

  useEffect(() => {
    if (!patientId && patientName?.length >= 2) {
      setFilteredPatients(
        patients.filter(patient =>
          patient.name.toLowerCase().includes(patientName),
        ),
      )
    }
  }, [patientName])

  useEffect(() => {
    const handleClickOutside = event => {
      if (patients.length) {
        setFilteredPatients([])
      }
    }

    document.addEventListener('click', handleClickOutside)

    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [])

  return (
    patients && (
      <div css={{ position: 'relative' }}>
        <div
          css={{
            position: 'absolute',
            background: 'white',
            width: '100%',
          }}
        >
          {filteredPatients.map(patient => (
            <div
              key={patient._id}
              css={[
                clickable,
                {
                  border: '1px solid #D9E0ED',
                  padding: '12px 8px',
                },
              ]}
              onClick={() => onSelectPatient_(patient)}
            >
              {patient.name}&nbsp;&nbsp;{patient.breed || patient.species}
            </div>
          ))}
        </div>
      </div>
    )
  )
}

const DropdownProcedures = ({ clinicId, onSelectProcedure }) => {
  const { t } = useTranslation()
  let { data: procedures } = useProcedures(
    {
      clinicId,
    },
    {
      enabled: !!clinicId,
    },
  )

  let [procedureName, setProcedureName] = useState('')
  let [filteredProcedures, setFilteredProcedures] = useState([])
  let [focused, setFocused] = useState(false)

  const changeProcedureName = event => {
    if (procedures?.length === 0) {
      toast(t('Add a procedure first'))
    } else {
      setProcedureName(event.target.value)
    }
  }

  let onSelectProcedure_ = procedure => {
    onSelectProcedure(procedure)
    setFilteredProcedures([])
  }

  let onFocus = () => {
    setFocused(true)
    if (procedures?.length === 0) {
      toast(t('Add a procedure first'))
    }
  }

  useEffect(() => {
    if (focused && procedureName?.length === 0) {
      setFilteredProcedures(procedures)
    } else if (procedureName?.length >= 1) {
      setFilteredProcedures(
        procedures.filter(procedure =>
          procedure.name.toLowerCase().includes(procedureName?.toLowerCase()),
        ),
      )
    }
  }, [procedureName, focused])

  return (
    procedures && (
      <>
        <input
          id="procedureNameInput"
          css={input}
          value={procedureName}
          onChange={changeProcedureName}
          autoComplete="off"
          placeholder={t('search a procedure')}
          onFocus={onFocus}
          onBlur={() => {
            // delay of 100 millisecons to allow onClick to be triggered
            setTimeout(() => {
              setFocused(false)
            }, 300)
          }}
        />

        {focused && (
          <div css={{ position: 'relative' }}>
            <div
              css={{
                position: 'absolute',
                background: 'white',
                width: '100%',
              }}
            >
              {filteredProcedures.map(procedure => (
                <div
                  key={procedure._id}
                  css={[
                    clickable,
                    {
                      border: '1px solid #D9E0ED',
                      padding: '12px 8px',
                    },
                  ]}
                  onClick={() => onSelectProcedure_(procedure)}
                >
                  {procedure.name}&nbsp;&nbsp;{procedure.price}
                </div>
              ))}
            </div>
          </div>
        )}
      </>
    )
  )
}

const AppointmentPage = () => {
  useLoggedInSession()
  const { clinicId, appointmentId } = useParams()
  let location = useLocation()
  let urlSearchParams = new URLSearchParams(location.search)
  let queryDateString = urlSearchParams.get('date')

  const navigate = useNavigate()
  const { t } = useTranslation()
  const isNewAppointment = appointmentId === 'new'

  const { data: clinic } = useClinic(clinicId)
  const { data: appointment } = useAppointment(appointmentId, {
    enabled: !!(clinic && !isNewAppointment),
  })

  let [clientId, setClientId] = useState(appointment?.clientId || null)
  let [patientId, setPatientId] = useState(appointment?.patientId || null)

  const { data: client } = useClient(clientId, {
    enabled: !!clientId,
  })

  const { data: patient } = usePatient(patientId, {
    enabled: !!patientId,
  })

  // console.log({ client })

  let startOfDay = startOfToday()
  // console.log('1', startOfDay)
  try {
    if (queryDateString) {
      let queryDate = parse(queryDateString, dateInputFormat, new Date())
      startOfDay = pipe(
        date => setYear(date, getYear(queryDate)),
        date => setMonth(date, getMonth(queryDate)),
        date => setDayOfYear(date, getDayOfYear(queryDate)),
      )(startOfDay)
    }
  } catch (error) {
    // DO NOTHING ON PURPOSE
  }
  // console.log('2', startOfDay)

  let startTimeDefault = pipe(date => setHours(date, 10), formatISO)(startOfDay)
  // console.log(`${startTimeDefault}`)
  let endTimeDefault = pipe(
    date => setHours(date, 10),
    date => setMinutes(date, 30),
    formatISO,
  )(startOfDay)
  // console.log(`${endTimeDefault}`)

  const [edited, setEdited] = useState(false)
  let [_id] = useState(appointment?._id || uuid())
  let [clientName, setClientName] = useState(client?.name || '')
  let [clientPhone, setClientPhone] = useState(client?.phone || '')
  const [startTime, setStartTime] = useState(
    appointment?.startTime || startTimeDefault,
  )
  const [endTime, setEndTime] = useState(appointment?.endTime || endTimeDefault)
  let [note, setNote] = useState(appointment?.note || '')
  let [procedures, setProcedures] = useState(appointment?.procedures || [])
  let [patientName, setPatientName] = useState(patient?.name || '')
  let [patientSpecies, setPatientSpecies] = useState(patient?.species || 'dog')
  let [patientBreed, setPatientBreed] = useState(patient?.breed || 'other')
  let dateInputValue = format(parseISO(startTime), dateInputFormat)
  // console.log({ dateInputValue })
  let startTimeInputValue = format(parseISO(startTime), timeInputFormat)
  // console.log({ startTimeInputValue })
  let endTimeInputValue = format(parseISO(endTime), timeInputFormat)
  // console.log({ endTimeInputValue })

  let isNewClient = !clientId
  let isNewPatient = !patientId

  const changeClientName = event => {
    let newClientName = event.target.value
    setEdited(true)
    setClientName(newClientName)

    if (newClientName.length === 0) {
      setClientId(null)
    }
  }

  const changePatientName = event => {
    let newPatientName = event.target.value
    setEdited(true)
    setPatientName(newPatientName)

    if (newPatientName.length === 0) {
      setPatientId(null)
    }
  }

  const changePatientSpecies = patientSpecies => {
    setEdited(true)
    setPatientSpecies(patientSpecies)
    setPatientBreed('other')
  }

  const changePatientBreed = patientBreed => {
    setEdited(true)
    setPatientBreed(patientBreed)
  }

  const onSelectClient = client => {
    setEdited(true)
    setClientId(client._id)
    setClientName(client.name)
    setClientPhone(client.phone)
  }

  const onSelectPatient = patient => {
    setEdited(true)
    setPatientId(patient._id)
    setPatientName(patient.name)
    setPatientSpecies(patient.species)
    setPatientBreed(patient.breed)
  }

  const onSelectProcedure = procedure => {
    setEdited(true)
    setProcedures([
      ...procedures,
      {
        _id: uuid(),
        procedureId: procedure._id,
        name: procedure.name,
        price: procedure.price,
      },
    ])
  }

  const changeClientPhone = event => {
    setClientPhone(event.target.value)
  }

  const changeDate = event => {
    let value = event.target.value
    console.log({ value })
    setEdited(true)
    // console.log('changeDate', event.target.value)
    let parsedDate = parse(value, 'yyyy-MM-dd', new Date())

    try {
      let newStartTime = pipe(
        parseISO,
        // date => {
        //   console.log(date)
        //   return date
        // },
        date => setYear(date, getYear(parsedDate)),
        date => setMonth(date, getMonth(parsedDate)),
        date => setDayOfYear(date, getDayOfYear(parsedDate)),
        formatISO,
      )(startTime)
      // console.log(newStartTime)
      setStartTime(newStartTime)
    } catch (error) {
      // DO NOTHING ON PURPOSE
    }

    try {
      let newEndTime = pipe(
        parseISO,
        date => setYear(date, getYear(parsedDate)),
        date => setMonth(date, getMonth(parsedDate)),
        date => setDayOfYear(date, getDayOfYear(parsedDate)),
        formatISO,
      )(endTime)
      // console.log(newEndTime)
      setEndTime(newEndTime)
    } catch (error) {
      // DO NOTHING ON PURPOSE
    }
  }

  const changeStartTime = event => {
    try {
      setEdited(true)
      // console.log('changeEndTime', event.target.value)
      let parsedTime = parse(event.target.value, timeInputFormat, new Date())
      // console.log(parsedTime)
      let newStartTime = pipe(
        parseISO,
        date => setHours(date, getHours(parsedTime)),
        date => setMinutes(date, getMinutes(parsedTime)),
        formatISO,
      )(startTime)
      // console.log(newStartTime)
      setStartTime(newStartTime)

      if (isBefore(new Date(endTime), new Date(newStartTime))) {
        let newEndTime = pipe(
          parseISO,
          date => setHours(date, getHours(newStartTime)),
          date => setMinutes(date, getMinutes(newStartTime)),
          date => addMinutes(date, defaultAppointmentLengthMinutes),
          formatISO,
        )(newStartTime)

        setEndTime(newEndTime)
      }
    } catch (error) {
      // DO NOTHING ON PURPOSE
    }
  }

  const changeEndTime = event => {
    try {
      setEdited(true)
      // console.log('changeEndTime', event.target.value)
      let parsedTime = parse(event.target.value, timeInputFormat, new Date())
      // console.log(parsedTime)
      let newEndTime = pipe(
        parseISO,
        date => setHours(date, getHours(parsedTime)),
        date => setMinutes(date, getMinutes(parsedTime)),
        formatISO,
      )(endTime)
      // console.log(newEndTime)
      setEndTime(newEndTime)
    } catch (error) {
      // DO NOTHING ON PURPOSE
    }
  }

  const changeNote = event => {
    setEdited(true)
    setNote(event.target.value)
  }

  let blocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      edited && currentLocation.pathname !== nextLocation.pathname,
  )

  useEffect(() => {
    if (!edited) {
      blocker?.proceed?.()
    }
  }, [edited])

  const addOrUpdateAppointment = async event => {
    event?.preventDefault?.()

    let creationTime = new Date().toISOString()
    let lastEditTime = creationTime

    try {
      let newClient
      if (isNewClient) {
        newClient = {
          _id: uuid(),
          creationTime,
          lastEditTime,
          organizationId: clinic.organizationId,
          clinicId: clinic._id,
          name: preferCapitalizedEachWord(clientName),
          phone: clientPhone,
        }
      }

      let newPatient
      if (isNewPatient) {
        newPatient = {
          _id: uuid(),
          creationTime,
          lastEditTime,
          organizationId: clinic.organizationId,
          clinicId: clinic._id,
          clientId: clientId || newClient._id,
          name: preferCapitalizedEachWord(patientName),
          species: patientSpecies,
          breed: patientBreed,
        }
      }

      const appointment = {
        _id,
        creationTime,
        lastEditTime,
        organizationId: clinic.organizationId,
        clinicId: clinic._id,
        clientId: clientId || newClient._id,
        patientId: patientId || newPatient._id,
        startTime,
        endTime,
        note,
        procedures,
      }

      // console.log(appointment)
      // console.log(newClient)

      let promises = []

      if (isNewAppointment) {
        promises.push(addAppointment(appointment))
      } else {
        promises.push(updateAppointment(appointment))
      }

      if (isNewClient) {
        promises.push(addClient(newClient))
      } else {
        promises.push(
          updateClient({
            ...client,
            lastEditTime,
            name: preferCapitalizedEachWord(clientName),
            phone: clientPhone,
          }),
        )
      }

      if (isNewPatient) {
        promises.push(addPatient(newPatient))
      } else {
        promises.push(
          updatePatient({
            ...patient,
            lastEditTime,
            name: preferCapitalizedEachWord(patientName),
            species: patientSpecies,
            breed: patientBreed,
          }),
        )
      }

      await Promise.all(promises)

      setEdited(false)
      navigate(`/clinics/${clinicId}/appointments`)
    } catch (error) {
      toastError(error)
    }
  }

  let closePrompt = () => {
    blocker.reset()
  }

  let discardChangesPrompt = () => {
    blocker.proceed()
  }

  let onDelete = async event => {
    event?.preventDefault?.()

    try {
      if (!isNewClient) {
        await deleteAppointment(appointmentId)
      }

      setEdited(false)
      navigate(`/clinics/${clinicId}/appointments`)
    } catch (error) {
      toastError(error)
    }
  }

  let saveChangesPrompt = async () => {
    await addOrUpdateAppointment()
    blocker.proceed()
  }

  useEffect(() => {
    if (appointment) {
      setStartTime(appointment.startTime)
      setEndTime(appointment.endTime)
      setNote(appointment.note)
      setProcedures(appointment.procedures || [])
    }
  }, [appointment])

  useEffect(() => {
    if (client) {
      setClientName(client.name)
      setClientPhone(client.phone)
    }
  }, [client])

  return (
    <Layout title={t(isNewAppointment ? 'New appointment' : 'Appointment')}>
      {blocker?.state === 'blocked' && (
        <SaveChangesPrompt
          close={closePrompt}
          discardChanges={discardChangesPrompt}
          saveChanges={saveChangesPrompt}
        />
      )}
      <form
        onSubmit={addOrUpdateAppointment}
        css={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}
      >
        <div css={section}>
          <AppBar>
            <AppBarTitle>
              {isNewClient ? t('New appointment') : t('Appointment')}
            </AppBarTitle>
            <div css={{ flexGrow: 1 }} />
            {!isNewClient && (
              <AppBarButton
                appearance="secondary"
                Icon={IconDelete}
                label={t('Delete')}
                onClick={onDelete}
              />
            )}
          </AppBar>

          <div css={[card, { margin: '0 16px' }]}>
            <label htmlFor="clientNameInput" css={label}>
              {t('Client name')}
            </label>
            <input
              id="clientNameInput"
              css={input}
              value={clientName}
              onChange={changeClientName}
              autoFocus
              autoComplete="off"
            />
            <DropdownClientName
              clinicId={clinic?._id}
              onSelectClient={onSelectClient}
              clientId={clientId}
              clientName={clientName}
            />
            <label htmlFor="clientPhoneInput" css={label}>
              {t('Client phone')}
            </label>
            <input
              id="clientPhoneInput"
              css={input}
              value={clientPhone}
              onChange={changeClientPhone}
            />
            <DropdownClientPhone
              clinicId={clinic?._id}
              onSelectClient={onSelectClient}
              clientId={clientId}
              clientPhone={clientPhone}
            />
            <label htmlFor="dateInput" css={label}>
              {t('Date')}
            </label>
            <input
              id="dateInput"
              css={input}
              type="date"
              value={dateInputValue}
              onChange={changeDate}
            />
            <label htmlFor="startTimeInput" css={label} value={startTime}>
              {t('Start time')}
            </label>
            <input
              id="startTimeInput"
              type="time"
              css={input}
              value={startTimeInputValue}
              onChange={changeStartTime}
            />
            <label htmlFor="endTimeInput" css={label}>
              {t('End time')}
            </label>
            <input
              id="endTimeInput"
              type="time"
              css={input}
              value={endTimeInputValue}
              onChange={changeEndTime}
            />
            <label htmlFor="noteInput" css={label}>
              {t('Note')}
            </label>
            <input
              id="noteInput"
              css={input}
              value={note}
              onChange={changeNote}
              autoComplete="off"
            />
            <label htmlFor="procedureNameInput" css={label}>
              {t('Procedures')}
            </label>
            <DropdownProcedures
              clinicId={clinic?._id}
              onSelectProcedure={onSelectProcedure}
            />
            {procedures?.map(procedure => (
              <div key={procedure?._id} css={{ margin: '16 16 16 0' }}>
                {procedure?.name}
              </div>
            ))}
          </div>
        </div>
        <div css={section}>
          <AppBar>
            <AppBarTitle>
              {isNewClient ? t('New patient') : t('Patient')}
            </AppBarTitle>
          </AppBar>

          <div css={[card, { margin: '0 16px' }]}>
            <label htmlFor="patientNameInput" css={label}>
              {t('Patient name')}
            </label>
            <input
              id="patientNameInput"
              css={input}
              value={patientName}
              onChange={changePatientName}
              autoFocus
              autoComplete="off"
            />
            <DropdownPatientName
              clinicId={clinic?._id}
              onSelectPatient={onSelectPatient}
              patientId={patientId}
              patientName={patientName}
            />
            <SpeciesButtons
              currentSpecies={patientSpecies}
              onSelectSpecies={changePatientSpecies}
            />
            <DropdownBreeds
              clinicId={clinic?._id}
              currentBreed={patientBreed}
              species={patientSpecies}
              onSelectBreed={changePatientBreed}
            />
          </div>
        </div>
        {(edited || isNewAppointment) && (
          <button
            type="submit"
            css={[
              clickable,
              {
                position: 'fixed',
                bottom: 0,
                zIndex: 999,
                height: 48,
                border: 0,
                color: 'white',
                background: 'var(--color-green)',
                width: '100%',
                left: 0,
                '@media (min-width: 992px)': {
                  left: 'calc(30vw + 100px)',
                  width: '40vw',
                  borderTopLeftRadius: 16,
                  borderTopRightRadius: 16,
                },
              },
            ]}
          >
            {isNewAppointment ? t('Add appointment') : t('Save appointment')}
          </button>
        )}
        {(edited || isNewAppointment) && (
          <div css={{ minHeight: 48, width: '100%' }} />
        )}
      </form>
    </Layout>
  )
}

export default AppointmentPage
