/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */

/* eslint-disable max-len */
import React, { useEffect, useState } from 'react'
import { css, jsx } from '@emotion/react'
import {
  unstable_useBlocker as useBlocker,
  useNavigate,
  useParams,
} from 'react-router-dom'
import addPatient from '@veterical/client/addPatient.js'
import deleteAppointments from '@veterical/client/deleteAppointments.js'
import deletePatient from '@veterical/client/deletePatient.js'
import updatePatient from '@veterical/client/updatePatient.js'
import useClinic from '@veterical/react/useClinic.js'
import usePatient from '@veterical/react/usePatient.js'
import preferCapitalizedEachWord from '@monorepo/shared/preferCapitalizedEachWord.js'
import { toastError } from '@monorepo/shared/AndroidToast.js'
import uuid from '@monorepo/shared/uuid.js'
import AppBar from '@stiloso/components/AppBar.js'
import AppBarButton from '@stiloso/components/AppBarButton.js'
import AppBarTitle from '@stiloso/components/AppBarTitle.js'
import DeleteDialog from '@stiloso/components/DeleteDialog.js'
import IconDelete from '@stiloso/icons/IconDelete.js'
import card from '@stiloso/styles/card.js'
import clickable from '@stiloso/styles/clickable.js'
import label from '@stiloso/styles/label.js'
import input from '@stiloso/styles/input.js'
import section from '@stiloso/styles/section.js'
import { useTranslation } from '@multilocale/react/index.js'
import DropdownBreeds from '../components/DropdownBreeds.js'
import Layout from '../components/Layout.js'
import SpeciesButtons from '../components/SpeciesButtons.js'
import SaveChangesPrompt from '../dialogs/SaveChangesPrompt.js'
import useLoggedInSession from '../session/useLoggedInSession.js'

export const paths = ['/clinics/:clinicId/patients/:patientId']

const PatientPage = () => {
  useLoggedInSession()
  const { clinicId, patientId } = useParams()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const isNewPatient = patientId === 'new'
  const { data: clinic } = useClinic(clinicId)
  const { data: patient } = usePatient(patientId, {
    enabled: clinic && !isNewPatient,
  })
  const [edited, setEdited] = useState(false)
  let [_id, setId] = useState(patient?._id || uuid())
  let [name, setName] = useState('')
  let [patientSpecies, setPatientSpecies] = useState(patient?.species || 'dog')
  let [patientBreed, setPatientBreed] = useState(patient?.breed || 'other')
  const [deleteConfirmationDialog, setDeleteConfirmationDialog] =
    useState(false)

  const changeName = event => {
    setEdited(true)
    setName(event.target.value)
  }

  const changePatientSpecies = patientSpecies => {
    setEdited(true)
    setPatientSpecies(patientSpecies)
    setPatientBreed('other')
  }

  const changePatientBreed = patientBreed => {
    setEdited(true)
    setPatientBreed(patientBreed)
  }

  let blocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      edited && currentLocation.pathname !== nextLocation.pathname,
  )

  useEffect(() => {
    if (!edited) {
      blocker?.proceed?.()
    }
  }, [edited])

  const addOrUpdatePatient = async event => {
    event?.preventDefault?.()

    let lastEditTime = new Date().toISOString()

    try {
      name = preferCapitalizedEachWord(name)
      const patient = {
        _id,
        lastEditTime,
        organizationId: clinic.organizationId,
        clinicId: clinic._id,
        name,
        species: patientSpecies,
        breed: patientBreed,
      }

      if (isNewPatient) {
        await addPatient({ ...patient, creationTime: lastEditTime })
      } else {
        await updatePatient(patient)
      }

      setEdited(false)
      navigate(`/clinics/${clinicId}/patients`)
    } catch (error) {
      toastError(error)
    }
  }

  let closePrompt = () => {
    blocker.reset()
  }

  let discardChangesPrompt = () => {
    blocker.proceed()
  }

  const showDeleteConfirmationDialog = () => {
    setDeleteConfirmationDialog(true)
  }

  const hideDeleteConfirmationDialog = () => {
    setDeleteConfirmationDialog(false)
  }

  let onDelete = async event => {
    event?.preventDefault?.()

    try {
      if (!isNewPatient) {
        await Promise.all([
          deletePatient(patientId),
          deleteAppointments({ patientId }),
        ])
      }

      setEdited(false)
      navigate(`/clinics/${clinicId}/patients`)
    } catch (error) {
      toastError(error)
    }
  }

  let saveChangesPrompt = async () => {
    await addOrUpdatePatient()
    blocker.proceed()
  }

  useEffect(() => {
    if (patient) {
      setId(patient._id)
      setName(patient.name)
    }
  }, [patient])

  return (
    <Layout title={t(isNewPatient ? 'New patient' : 'Patient')}>
      {blocker?.state === 'blocked' && (
        <SaveChangesPrompt
          close={closePrompt}
          discardChanges={discardChangesPrompt}
          saveChanges={saveChangesPrompt}
        />
      )}
      {deleteConfirmationDialog && (
        <DeleteDialog
          title={t('Delete patient')}
          message={t(
            'Do you want to delete patient {patient_name}’s appointments and related records?',
          ).replace('{patient_name}', name)}
          onDelete={onDelete}
          close={hideDeleteConfirmationDialog}
          // loading={deletePatientMutation.isLoading}
        />
      )}
      <form
        onSubmit={addOrUpdatePatient}
        css={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}
      >
        <div css={[section]}>
          <AppBar>
            <AppBarTitle>
              {isNewPatient ? t('New patient') : t('Patient') + ' ' + name}
            </AppBarTitle>
            <div css={{ flexGrow: 1 }} />
            {!isNewPatient && (
              <AppBarButton
                appearance="secondary"
                Icon={IconDelete}
                label={t('Delete')}
                onClick={showDeleteConfirmationDialog}
              />
            )}
          </AppBar>
          <div css={[card, { margin: '0 16px' }]}>
            <label htmlFor="nameInput" css={label}>
              {t('Name')}
            </label>
            <input
              id="nameInput"
              css={input}
              value={name}
              onChange={changeName}
              autoFocus
              autoComplete="off"
            />
            <SpeciesButtons
              currentSpecies={patientSpecies}
              onSelectSpecies={changePatientSpecies}
            />
            <DropdownBreeds
              clinicId={clinic?._id}
              currentBreed={patientBreed}
              species={patientSpecies}
              onSelectBreed={changePatientBreed}
            />
          </div>
        </div>
        {(edited || isNewPatient) && (
          <button
            type="submit"
            css={[
              clickable,
              {
                position: 'fixed',
                bottom: 0,
                zIndex: 999,
                height: 48,
                border: 0,
                color: 'white',
                background: 'var(--color-green)',
                width: '100%',
                left: 0,
                '@media (min-width: 992px)': {
                  left: 'calc(30vw + 100px)',
                  width: '40vw',
                  borderTopLeftRadius: 16,
                  borderTopRightRadius: 16,
                },
              },
            ]}
          >
            {isNewPatient ? t('Add patient') : t('Save patient')}
          </button>
        )}
        {(edited || isNewPatient) && <div css={{ minHeight: 48 }} />}
      </form>
    </Layout>
  )
}

export default PatientPage
