/* Copyright 2013 - 2024 Waiterio LLC */
import fetch from '@monorepo/fetch/index.js'
import getVetericalApiUrl from '@monorepo/env/getVetericalApiUrl.js'

export default (email, password) =>
  fetch({
    url: getVetericalApiUrl() + '/api/login',
    method: 'POST',
    headers: {
      Authorization: `Basic ${btoa(email + ':' + password)}`,
    },
  })
