/* Copyright 2013 - 2024 Waiterio LLC */
import { useQuery } from '@tanstack/react-query'
import getPatient from '@veterical/client/getPatient.js'

export default function usePatient(patientId, options) {
  return useQuery({
    queryKey: ['patients', patientId],
    queryFn: () => getPatient(patientId),
    ...options,
  })
}
