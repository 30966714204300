/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import React from 'react'
import { Link, useParams } from 'react-router-dom'
import { css, jsx } from '@emotion/react'
import useAppointments from '@veterical/react/useAppointments.js'
import useClinic from '@veterical/react/useClinic.js'
import useClients from '@veterical/react/useClients.js'
import useUser from '@veterical/react/useUser.js'
import { format, formatISO, startOfToday } from 'date-fns'
import card from '@stiloso/styles/card.js'
import clickable from '@stiloso/styles/clickable.js'
import section from '@stiloso/styles/section.js'
import AppBar from '@stiloso/components/AppBar.js'
import AppBarButton from '@stiloso/components/AppBarButton.js'
import AppBarTitle from '@stiloso/components/AppBarTitle.js'
import { useTranslation } from '@multilocale/react/index.js'
import Layout from '../components/Layout.js'
import getUserId from '../session/getUserId.js'
import useLoggedInSession from '../session/useLoggedInSession.js'

let appointmentCard = css([
  clickable,
  card,
  {
    lineHeight: '48px',
    width: '100%',
    marginTop: 16,
    padding: '0 16',
  },
])

export const paths = ['/clinics/:clinicId/appointments']

const AppointmentsPage = () => {
  useLoggedInSession()
  const { clinicId } = useParams()
  const { t } = useTranslation()
  let { data: clinic } = useClinic(clinicId)
  let { data: user } = useUser(getUserId())

  let startTime = formatISO(startOfToday())

  let { data: appointments } = useAppointments({
    clinicId: clinic._id,
    startTime,
  })

  let clientsIds = Object.keys(
    appointments?.reduce((clientsIds, appointment) => {
      if (appointment.clientId) {
        clientsIds[appointment.clientId] = true
      }

      return clientsIds
    }, {}),
  )

  // console.log(clientsIds)

  let { data: clients } = useClients({
    clinicId: clinic._id,
    _ids: clientsIds,
  })

  // console.log({ clients })

  let ids2clients = clients?.reduce((ids2clients, client) => {
    ids2clients[client._id] = client

    return ids2clients
  }, {})

  appointments = appointments?.sort((a, b) =>
    a.startTime > b.startTime ? 1 : -1,
  )

  appointments = appointments?.map(appointment => ({
    ...appointment,
    client: ids2clients[appointment.clientId],
  }))

  // console.log({ appointments })

  let days2appointments = appointments.reduce(
    (days2appointments, appointment) => {
      let day = appointment.startTime.split('T')[0]

      if (!days2appointments[day]) {
        days2appointments[day] = []
      }

      days2appointments[day].push(appointment)

      return days2appointments
    },
    {},
  )

  let today = new Date().toISOString().split('T')[0]
  let tomorrow = new Date(new Date().getTime() + 24 * 60 * 60 * 1000)
    .toISOString()
    .split('T')[0]

  let days = Object.keys(days2appointments)
    .sort()
    .map(day => {
      let label = new Date(day).toLocaleDateString(user?.language || 'en', {
        weekday: 'long',
        month: 'long',
        day: 'numeric',
      })

      if (day === today) {
        label = t('Today') + ', ' + label
      } else if (day === tomorrow) {
        label = t('Tomorrow') + ', ' + label
      }

      return { label, appointments: days2appointments[day] }
    })
  // console.log(days)

  return (
    <Layout title={t('Appointments')}>
      <div css={section}>
        <AppBar>
          <AppBarTitle>{t('Appointments')}</AppBarTitle>
          <div css={{ flexGrow: 1 }} />
          <AppBarButton
            label={t('New appointment')}
            to={`/clinics/${clinicId}/appointments/new`}
          />
        </AppBar>
        <div
          css={{
            padding: 16,
            display: 'flex',
            flexWrap: 'wrap',
          }}
        >
          {days?.map(({ label, appointments }, i) => (
            <div key={label} css={{ width: '100%' }}>
              <div css={{ paddingTop: i > 0 ? 16 : 0 }}>{label}</div>
              {appointments?.map(({ _id, startTime, client }) => (
                <Link
                  key={_id}
                  css={appointmentCard}
                  to={`/clinics/${clinicId}/appointments/${_id}`}
                >
                  {format(startTime, 'HH:mm')}&nbsp;&nbsp;{client?.name || ''}
                </Link>
              ))}
            </div>
          ))}
        </div>
      </div>
    </Layout>
  )
}

export default AppointmentsPage
