/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import { useTranslation } from '@multilocale/react/index.js'
import ForgotPasswordPage from '@stiloso/pages/ForgotPasswordPage.js'
import IllustrationVeterinary from '../components/IllustrationVeterinary.js'
import postForgotPassword from '../postForgotPassword.js'

export const paths = ['/forgotpassword', '/forgot-password']

const ForgotPasswordPageWrapper = () => {
  const { t } = useTranslation()

  const resetPassword = async ({ email }) => {
    await postForgotPassword(email)
  }

  let brand = (
    <div css={{ color: 'var(--color-primary)', fontSize: 36 }}>
      {t('__brand__')}
    </div>
  )

  return (
    <ForgotPasswordPage
      brand={brand}
      resetPassword={resetPassword}
      illustration={<IllustrationVeterinary />}
    />
  )
}

export default ForgotPasswordPageWrapper
