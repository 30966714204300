/* Copyright 2013 - 2024 Waiterio LLC */
import { useQuery } from '@tanstack/react-query'
import getProcedure from '@veterical/client/getProcedure.js'

export default function useProcedure(procedureId, options) {
  return useQuery({
    queryKey: ['procedures', procedureId],
    queryFn: () => getProcedure(procedureId),
    ...options,
  })
}
