/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import { Suspense } from 'react'
import { css, jsx } from '@emotion/react'
import { Link } from 'react-router-dom'
import useClinics from '@veterical/react/useClinics.js'
import Spinner from '@stiloso/components/Spinner.js'
import card from '@stiloso/styles/card.js'
import clickable from '@stiloso/styles/clickable.js'
import AppBar from '@stiloso/components/AppBar.js'
import AppBarButton from '@stiloso/components/AppBarButton.js'
import AppBarTitle from '@stiloso/components/AppBarTitle.js'
import { useTranslation } from '@multilocale/react/index.js'
import ErrorBoundary from '../components/ErrorBoundary.js'
import Layout from '../components/Layout.js'
import useLoggedInSession from '../session/useLoggedInSession.js'

export const paths = ['/clinics']

let clinicCard = css([
  clickable,
  card,
  {
    lineHeight: '48px',
    width: '100%',
    marginTop: 16,
    padding: '0 16',
  },
])

const ClinicsList = () => {
  let { data: clinics } = useClinics()
  clinics = clinics?.sort((a, b) => (a.name > b.name ? 1 : -1))
  return (
    <div
      css={{
        padding: 16,
        display: 'flex',
        direction: 'column',
        flexWrap: 'wrap',
      }}
    >
      {clinics?.map(({ name }) => (
        <Link key={name} css={clinicCard} to={`/clinics/${name}/appointments/`}>
          {name}
        </Link>
      ))}
    </div>
  )
}

const ClinicsPage = () => {
  useLoggedInSession()
  const { t } = useTranslation()

  return (
    <Layout title={t('Clinics')}>
      <div css={{ margin: '32px auto', maxWidth: 640, width: '100%' }}>
        <AppBar>
          <AppBarTitle>{t('Clinics')}</AppBarTitle>
          <div css={{ flexGrow: 1 }} />
          <AppBarButton label={t('New clinic')} to="/clinics/new" />
        </AppBar>
        <ErrorBoundary>
          <Suspense fallback={<Spinner />}>
            <ClinicsList />
          </Suspense>
        </ErrorBoundary>
      </div>
    </Layout>
  )
}

export default ClinicsPage
