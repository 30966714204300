/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import { useTranslation } from '@multilocale/react/index.js'
import getClinics from '@veterical/client/getClinics.js'
import LoginPage from '@stiloso/pages/LoginPage.js'
import { useNavigate } from 'react-router-dom'
import postLogin from '../postLogin.js'
import IllustrationVeterinary from '../components/IllustrationVeterinary.js'
import storeNewSession from '../session/storeNewSession.js'

export const paths = ['/login']

const LoginPageWrapper = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const login = async ({ email, password }) => {
    const loginResponse = await postLogin(email, password)
    await storeNewSession(loginResponse)

    const clinics = await getClinics()

    if (clinics.length === 1) {
      navigate(`/clinics/${clinics[0]._id}/appointments`)
    } else {
      navigate('/clinics')
    }
  }

  let brand = (
    <div css={{ color: 'var(--color-primary)', fontSize: 36 }}>
      {t('Veterical')}
    </div>
  )

  return (
    <LoginPage
      brand={brand}
      login={login}
      illustration={<IllustrationVeterinary />}
    />
  )
}

export default LoginPageWrapper
