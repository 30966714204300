/* Copyright 2013 - 2024 Waiterio LLC */
import { useQuery } from '@tanstack/react-query'
import getClinics from '@veterical/client/getClinics.js'

export default function useClinics(parameters) {
  parameters ||= {}
  const { enabled } = parameters

  return useQuery({
    queryKey: ['clinics'],
    queryFn: () => getClinics(),
    enabled,
  })
}
