/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import React, { useState } from 'react'
import { css, jsx } from '@emotion/react'
import { useTranslation } from '@multilocale/react/index.js'
import { Link } from 'react-router-dom'
import Button from '@stiloso/components/Button.js'
import label from '@stiloso/styles/label.js'
import input from '@stiloso/styles/input.js'
import link from '@stiloso/styles/link.js'
import dNone from '@stiloso/styles/bootstrap/dNone.js'
import dLgFlex from '@stiloso/styles/bootstrap/dLgFlex.js'
import IllustrationManWorkingOnComputer from '@stiloso/components/IllustrationManWorkingOnComputer.js'

const SignupPage = ({
  email: emailPrefilled,
  password: passwordPrefilled,
  brand,
  illustration,
  signup,
}) => {
  const { t } = useTranslation()
  let [email, setEmail] = useState(emailPrefilled || '')
  passwordPrefilled = passwordPrefilled ? atob(passwordPrefilled) : null
  let [password, setPassword] = useState(passwordPrefilled || '')
  let [error, setError] = useState()
  let [loading, setLoading] = useState(false)

  const changeEmail = event => {
    setEmail(event.target.value)
  }

  const changePassword = event => {
    setPassword(event.target.value)
  }

  const showError = error => {
    console.error(error)

    setError(error?.message)
  }

  const onSubmit = async event => {
    try {
      event.preventDefault()

      setLoading(true)
      await signup({
        email,
        password,
      })
      setLoading(false)
    } catch (error) {
      setLoading(false)
      showError(error)
    }
  }

  illustration ||= (
    <IllustrationManWorkingOnComputer
      css={{
        width: '100%',
        height: 'auto',
        objectFit: 'cover',
      }}
    />
  )

  return (
    <div
      css={{
        background: 'white',
        display: 'flex',
        flex: 1,
        justifyContent: 'space-between',
        height: '100%',
      }}
    >
      <div
        css={{
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <div
          css={{
            margin: 'auto',
            padding: '0px 16px',
            lineHeight: '48px',
            maxWidth: 480,
            width: '100%',
          }}
        >
          <Link to="/">{brand}</Link>
        </div>
        <div
          css={{
            margin: 'auto',
            padding: '0px 16px',
            maxWidth: 480,
            width: '100%',
          }}
        >
          <div
            css={{
              fontSize: 24,
              fontWeight: '700',
              textTransform: 'capitalize',
              lineHeight: '24px',
              color: 'var(--color-base)',
            }}
          >
            {t('Create your account')}
          </div>
          <form onSubmit={onSubmit}>
            <div css={{ marginTop: 32 }}>
              <label htmlFor="emailInput" css={label}>
                {t('email')}
              </label>
              <input
                css={input}
                id="emailInput"
                type="email"
                name="email"
                autoComplete="email"
                autoCapitalize="none"
                autoCorrect="off"
                value={email || ''}
                onChange={changeEmail}
                required
                autoFocus
                data-hj-allow
              />
            </div>
            <div css={{ marginTop: 16 }}>
              <label htmlFor="passwordInput" css={label}>
                {t('password')}
              </label>
              <input
                css={input}
                id="passwordInput"
                type="password"
                name="password"
                autoComplete="current-password"
                autoCapitalize="none"
                autoCorrect="off"
                required
                value={password || ''}
                onChange={changePassword}
              />
            </div>
            <Button
              type="submit"
              css={{ marginTop: 32 }}
              isLoading={loading}
              fullWidth
            >
              {t('Sign up')}
            </Button>
          </form>
          <div
            css={{
              display: 'flex',
              fontSize: 14,
              lineHeight: '24px',
              color: 'var(--color-base)',
              width: '100%',
              marginTop: 12,
              textAlign: 'center',
            }}
          >
            <Link
              to="/forgotpassword"
              css={[link, { padding: '12px 12px 12px 0px' }]}
            >
              {t('Forgot password')}
            </Link>
            <div css={{ flexGrow: 1 }} />
            <Link to="/login" css={[link, { padding: '12px 0px 12px 12px' }]}>
              {t('Login')}
            </Link>
          </div>
          {error && (
            <div
              css={{
                textAlign: 'center',
                lineHeight: '24px',
                marginTop: 32,
                color: 'red',
              }}
            >
              {error}
            </div>
          )}
        </div>
        <div css={{ margin: 'auto' }} />
      </div>
      <div css={[dNone, dLgFlex, { flex: 1, display: 'flex' }]}>
        {illustration}
      </div>
    </div>
  )
}

export default SignupPage
