/* Copyright 2013 - 2024 Waiterio LLC */
import React from 'react'

const IconDog = props => {
  const { className, width = 24, height = 24, onClick, style } = props
  const dataTestId = props['data-testid'] || 'icon-dog'

  return (
    <svg
      className={className}
      style={style}
      width={width}
      height={height}
      viewBox="0 0 16 16"
      onClick={onClick}
      data-testid={dataTestId}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 4V7C16 9.20914 14.2091 11 12 11H10V15H0V13L0.931622 10.8706C1.25226 10.9549 1.59036 11 1.94124 11C3.74931 11 5.32536 9.76947 5.76388 8.01538L3.82359 7.53031C3.60766 8.39406 2.83158 9.00001 1.94124 9.00001C1.87789 9.00001 1.81539 8.99702 1.75385 8.99119C1.02587 8.92223 0.432187 8.45551 0.160283 7.83121C0.0791432 7.64491 0.0266588 7.44457 0.00781272 7.23658C-0.0112323 7.02639 0.00407892 6.80838 0.0588889 6.58914C0.0588882 6.58914 0.0588896 6.58913 0.0588889 6.58914L0.698705 4.02986C1.14387 2.24919 2.7438 1 4.57928 1H10L12 4H16ZM9 6C9.55229 6 10 5.55228 10 5C10 4.44772 9.55229 4 9 4C8.44771 4 8 4.44772 8 5C8 5.55228 8.44771 6 9 6Z"
      />
    </svg>
  )
}

export default IconDog
