/* Copyright 2013 - 2024 Waiterio LLC */
import {
  setAccessTokenForVetericalClient,
  setAccessTokenCallbackForVetericalClient,
} from '@veterical/client/accessToken.js'
import { setRefreshTokenForVetericalClient } from '@veterical/client/refreshToken.js'
import getAccessToken from './getAccessToken.js'
import getRefreshToken from './getRefreshToken.js'
import setAccessToken from './setAccessToken.js'

export default async function rehydrateSession() {
  const accessToken = getAccessToken()
  const refreshToken = getRefreshToken()

  setAccessTokenForVetericalClient(accessToken)
  setRefreshTokenForVetericalClient(refreshToken)
  setAccessTokenCallbackForVetericalClient(setAccessToken)
}
