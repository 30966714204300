/* Copyright 2013 - 2024 Waiterio LLC */
import { useQuery } from '@tanstack/react-query'
import getClinic from '@veterical/client/getClinic.js'

export default function useClinic(clinicIdOrName, organizationId) {
  return useQuery({
    queryKey: ['clinics', clinicIdOrName],
    queryFn: () => getClinic(clinicIdOrName, organizationId),
    enabled: !!clinicIdOrName,
  })
}
