/* Copyright 2013 - 2024 Waiterio LLC */
import React from 'react'
import ErrorBoundaryStiloso from '@stiloso/components/ErrorBoundary.js'
import useQueryErrorResetBoundary from '@veterical/react/useQueryErrorResetBoundary.js'

const ErrorBoundary = ({ children }) => {
  const { reset } = useQueryErrorResetBoundary()
  return <ErrorBoundaryStiloso onReset={reset}>{children}</ErrorBoundaryStiloso>
}

export default ErrorBoundary
