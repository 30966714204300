/* Copyright 2013 - 2024 Waiterio LLC */
import preferCapitalizedEachWord from '@monorepo/shared/preferCapitalizedEachWord.js'
import domainProduction from '@veterical/shared/domainProduction.js'
import getUser from '@veterical/client/getUser.js'
import getUserId from './session/getUserId.js'

export default function identify() {
  const userId = getUserId()

  if (typeof window !== 'undefined' && userId) {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: 'login',
      userId,
    })

    getUser(userId).then(user => {
      window.$globo = window.$globo || []
      window.$globo.push(['showChat'])
      window.$globo.push(['setUserId', userId])

      if (user.email) {
        window.$globo.push(['setUserEmail', user.email])

        let nickname = (user?.firstName || '') + ' ' + (user?.lastName || '')
        nickname = nickname.trim()
        if (!nickname) {
          nickname = user.email
          nickname = nickname.split('@')[0] // eslint-disable-line prefer-destructuring
          nickname = nickname.replaceAll('.', ' ')
          nickname = preferCapitalizedEachWord(nickname)
        }

        nickname = nickname || user.email

        nickname = nickname.trim()

        window.$globo.push(['setUserNickname', nickname])
        window.$globo.push([
          'setMetadata',
          'analytics',
          `https://analytics.${domainProduction}/users/${userId}`,
        ])

        window.$globo.push([
          'setMetadata',
          'posthog',
          `https://app.posthog.com/person/${userId}#activeTab=sessionRecordings`,
        ])

        window.posthog?.identify(userId, {
          email: user.email,
          name: nickname,
          analytics: `https://analytics.${domainProduction}/users/${userId}`,
        })

        window.metricsmage?.identify(userId, {
          email: user.email,
          name: nickname,
          analytics: `https://analytics.${domainProduction}/users/${userId}`,
        })
      }
    })
  }
}
