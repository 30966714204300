/* Copyright 2013 - 2024 Waiterio LLC */
import React from 'react'

const IconLizard = props => {
  const { className, width = 24, height = 24, onClick, style } = props
  const dataTestId = props['data-testid'] || 'icon-lizard'

  return (
    <svg
      className={className}
      style={style}
      width={width}
      height={height}
      viewBox="0 0 357.957 357.957"
      onClick={onClick}
      data-testid={dataTestId}
    >
      <path d="M23.945,178.977c-0.809-11.568,39.906-18.752,66.238-19.553c26.332-0.792,40.311,5.586,40.311,9.576 c0,3.996-31.928,7.968-32.326,18.349c-0.393,10.401,4.785,11.191,15.166,11.992c10.371,0.786,43.654,3.969,44.051-3.208 c0.402-7.167-34.078-3.177-34.078-7.167c0-3.995,40.307-6.81,40.703-22.758c13.174,7.193,61.109,41.904,101.016,41.904 c24.777,0.44,24.934-4.003,24.934-7.586c0-3.577-4.584-9.178-26.932-8.776c-22.342,0.398-35.566-9.178-34.77-12.773 c0.797-3.592,20.359-0.007,33.521,0c38.916,0,96.178-16.713,96.178-48.652c0-11.54-19.955-20.019-45.898-20.389 c-26.732-0.375-27.129,16.771-26.732,25.149c0.395,8.383,1.195,15.171-3.193,15.556c-4.391,0.414-33.963-40.695-121.316-40.705 C56.662,109.928-0.398,135.482,0.002,177.379c0.395,41.909,203.52,70.649,203.52,70.649 C169.197,233.25,24.744,190.537,23.945,178.977z" />
    </svg>
  )
}

export default IconLizard
