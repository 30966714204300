/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import React from 'react'
import { Link, useParams } from 'react-router-dom'
import { css, jsx } from '@emotion/react'
import useProcedures from '@veterical/react/useProcedures.js'
import useClinic from '@veterical/react/useClinic.js'
import card from '@stiloso/styles/card.js'
import clickable from '@stiloso/styles/clickable.js'
import section from '@stiloso/styles/section.js'
import AppBar from '@stiloso/components/AppBar.js'
import AppBarButton from '@stiloso/components/AppBarButton.js'
import AppBarTitle from '@stiloso/components/AppBarTitle.js'
import { useTranslation } from '@multilocale/react/index.js'
import Layout from '../components/Layout.js'
import useLoggedInSession from '../session/useLoggedInSession.js'

let procedureCard = css([
  clickable,
  card,
  {
    lineHeight: '48px',
    width: '100%',
    marginTop: 16,
    padding: '0 16',
    display: 'flex',
    flexDirection: 'row',
  },
])

export const paths = ['/clinics/:clinicId/procedures']

const ProceduresPage = () => {
  useLoggedInSession()
  const { clinicId } = useParams()
  const { t } = useTranslation()
  let { data: clinic } = useClinic(clinicId)

  let { data: procedures } = useProcedures({
    clinicId: clinic._id,
  })

  return (
    <Layout title={t('Procedures')}>
      <div css={section}>
        <AppBar>
          <AppBarTitle>{t('Procedures')}</AppBarTitle>
          <div css={{ flexGrow: 1 }} />
          <AppBarButton
            label={t('New procedure')}
            to={`/clinics/${clinicId}/procedures/new`}
          />
        </AppBar>
        <div
          css={{
            padding: 16,
            display: 'flex',
            flexWrap: 'wrap',
          }}
        >
          {procedures?.map(({ _id, name, price }) => (
            <Link
              key={name}
              css={procedureCard}
              to={`/clinics/${clinicId}/procedures/${_id}`}
            >
              <div css={{ flexGrow: 1 }}>{name}</div>
              <div>{price}</div>
            </Link>
          ))}
        </div>
      </div>
    </Layout>
  )
}

export default ProceduresPage
