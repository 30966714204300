/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import React, { Suspense, useEffect, useState } from 'react'
import { jsx } from '@emotion/react'
import { unstable_useBlocker as useBlocker } from 'react-router-dom'
import useUser from '@veterical/react/useUser.js'
import useUserMutation from '@veterical/react/useUserMutation.js'
// import { toastError } from '@monorepo/shared/AndroidToast.js'
import section from '@stiloso/styles/section.js'
import AppBar from '@stiloso/components/AppBar.js'
import AppBarTitle from '@stiloso/components/AppBarTitle.js'
import DeleteDialog from '@stiloso/components/DeleteDialog.js'
// import getImageFromEvent from '@monorepo/shared/getImageFromEvent.js'
import preferCapitalized from '@monorepo/shared/preferCapitalized.js'
// import useUploadAvatarMutation from '@veterical/react/useUploadAvatarMutation.js'
import { useTranslation } from '@multilocale/react/index.js'
import getBrowserLanguage from '@monorepo/shared/getBrowserLanguage.js'
import languages from '@stiloso/shared/languages.js'
import card from '@stiloso/styles/card.js'
import LanguageAutocomplete from '@stiloso/components/LanguageAutocomplete.js'
// import ImageGraceful from '@stiloso/components/ImageGraceful.js'
import Spinner from '@stiloso/components/Spinner.js'
import Button from '@stiloso/components/Button.js'
import ErrorDialog from '@stiloso/components/ErrorDialog.js'
// import IconDelete from '@stiloso/icons/IconDelete.js'
import label from '@stiloso/styles/label.js'
import input from '@stiloso/styles/input.js'
// import UploadImageComponent from '@stiloso/components/UploadImage.js'
import ErrorBoundary from '../components/ErrorBoundary.js'
import Layout from '../components/Layout.js'
import getRoles from '../session/getRoles.js'
import getUserId from '../session/getUserId.js'
import SaveChangesPrompt from '../dialogs/SaveChangesPrompt.js'
import useLoggedInSession from '../session/useLoggedInSession.js'

export const paths = ['/account']

const UserSettings = () => {
  const { t } = useTranslation()
  const { mutate: mutateUser, isPending, reset, error } = useUserMutation()
  const roles = getRoles()
  const { data: user } = useUser(getUserId())
  // const uploadUserAvatar = useUploadAvatarMutation()
  const browserLanguage = getBrowserLanguage()

  const [edited, setEdited] = useState(false)
  let [firstName, setFirstName] = useState(user.firstName || '')
  let [lastName, setLastName] = useState(user.lastName || '')
  let [language, setLanguage] = useState(user.language || browserLanguage)
  const [deleteConfirmationDialog, setDeleteConfirmationDialog] =
    useState(false)

  const languages_ = Object.keys(languages).map(languageCode => ({
    code: languageCode,
    name: languages[languageCode],
  }))

  const changeFirstName = event => {
    setEdited(true)
    setFirstName(event.target.value)
  }

  const changeLastName = event => {
    setEdited(true)
    setLastName(event.target.value)
  }

  const changeLanguage = language => {
    setEdited(true)
    setLanguage(language)
  }

  let blocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      edited && currentLocation.pathname !== nextLocation.pathname,
  )

  useEffect(() => {
    if (!edited) {
      blocker?.proceed?.()
    }
  }, [edited])

  const changeUser = event => {
    event?.preventDefault?.()

    if (error) {
      reset()
    }

    firstName = preferCapitalized(firstName)
    lastName = preferCapitalized(lastName)

    const updatedUser = {
      ...user,
      firstName,
      lastName,
      language,
      lastEditTime: new Date().toISOString(),
    }

    mutateUser(updatedUser)
    setEdited(false)
  }

  // const deleteUserAvatar = () => {
  //   setEdited(true)
  //   const userWithoutAvatar = {
  //     ...user,
  //     avatarUrl: null,
  //   }

  //   mutateUser(userWithoutAvatar)
  //   setEdited(false)
  // }

  // const onClickUploadAvatar = () => {
  //   document.getElementById('uploadAvatarInput').click()
  // }

  // const uploadAvatar = async event => {
  //   try {
  //     let imageFile = await getImageFromEvent(event)
  //     uploadUserAvatar.mutate({ file: imageFile, userId: user._id })
  //     setEdited(true)
  //   } catch (error) {
  //     toastError(error)
  //   }
  // }

  const showDeleteConfirmationDialog = () => {
    setDeleteConfirmationDialog(true)
  }

  const hideDeleteConfirmationDialog = () => {
    setDeleteConfirmationDialog(false)
  }

  let onDeleteAccount = async event => {
    event?.preventDefault?.()
    hideDeleteConfirmationDialog()
    if (window.$globo) {
      window.$globo.push(['showChat'])
      window.$globo.push(['openChat'])
    } else {
      window.open('mailto:info@veterical.com', '_blank')
    }
  }

  let closePrompt = () => {
    blocker.reset()
  }

  let discardChangesPrompt = () => {
    blocker.proceed()
  }

  let saveChangesPrompt = async () => {
    await changeUser()
    blocker.proceed()
  }

  useEffect(() => {
    if (user) {
      setFirstName(user.firstName)
      setLastName(user.lastName)
      setLanguage(user.language)
    }
  }, [user])

  return (
    <>
      {blocker?.state === 'blocked' && (
        <SaveChangesPrompt
          close={closePrompt}
          discardChanges={discardChangesPrompt}
          saveChanges={saveChangesPrompt}
        />
      )}
      {deleteConfirmationDialog && (
        <DeleteDialog
          title={t('Delete account')}
          message={t(
            'Do you want to permanently delete your account and all data related to your clinic?',
          )}
          onDelete={onDeleteAccount}
          close={hideDeleteConfirmationDialog}
          // loading={deleteClientMutation.isLoading}
        />
      )}
      <form onSubmit={changeUser} css={[card, { margin: 16 }]}>
        <div>
          <label htmlFor="email" css={label}>
            {t('email')}
          </label>
          <input id="email" css={input} value={user.email} readOnly />
        </div>
        <div>
          <label htmlFor="firstName" css={label}>
            {t('First name')}
          </label>
          <input
            id="firstName"
            name="firstName"
            css={input}
            value={firstName}
            onChange={changeFirstName}
          />
        </div>
        <div>
          <label htmlFor="lastName" css={label}>
            {t('Last name')}
          </label>
          <input
            id="lastName"
            name="lastName"
            css={input}
            value={lastName}
            onChange={changeLastName}
          />
        </div>
        <LanguageAutocomplete
          inputLabel="UI language"
          languages={languages_}
          selectedLanguage={language}
          onSelectLanguage={changeLanguage}
        />
        {/* <div css={{ width: 140 }}>
          <label css={label}>{t('Upload avatar')}</label>
          {user.avatarUrl && (
            <div css={{ position: 'relative', width: 128 }}>
              <ImageGraceful
                css={{
                  aspectRatio: '1 / 1',
                  height: 128,
                  borderRadius: 1000,
                }}
                retry={{
                  count: 10,
                  delay: 1,
                  accumulate: false,
                }}
                src={user.avatarUrl.replace('.jpg', '-256w.webp')}
                fallbackSrc={user.avatarUrl.replace('.jpg', '-256w.jpg')}
              />

              <div
                css={{
                  position: 'absolute',
                  top: 0,
                  right: 0,
                  padding: 4,
                  borderRadius: 4,
                  backgroundColor: 'var(--color-primary)',
                  cursor: 'pointer',
                  ':hover': {
                    backgroundColor: 'var(--color-primary-light)',
                  },
                  ':active': {
                    backgroundColor: 'var(--color-primary-dark)',
                  },
                }}
                onClick={deleteUserAvatar}
              >
                <IconDelete css={{ fill: 'white' }} />
              </div>
            </div>
          )}
          {!user.avatarUrl && (
            <UploadImageComponent
              inputId="uploadAvatarInput"
              onClickUploadInput={onClickUploadAvatar}
              uploadImage={uploadAvatar}
              isPending={uploadUserAvatar.isPending}
              styles={{ borderRadius: 1000 }}
            />
          )}
        </div> */}

        {edited && (
          <Button
            type="submit"
            css={{ marginTop: 16 }}
            isLoading={isPending}
            fullWidth
          >
            {t('Save')}
          </Button>
        )}
      </form>
      {!edited && roles?.some(role => role?.type === 'admin') && (
        <div css={section}>
          <Button
            appearance="danger"
            css={{ margin: 16 }}
            onClick={showDeleteConfirmationDialog}
          >
            {t('Delete account')}
          </Button>
        </div>
      )}
      {error && <ErrorDialog error={error} close={reset} />}
    </>
  )
}

const AccountPage = () => {
  useLoggedInSession()
  const { t } = useTranslation()

  return (
    <Layout title={t('Account')}>
      <div css={section}>
        <AppBar>
          <AppBarTitle>{t('Account')}</AppBarTitle>
        </AppBar>
        <ErrorBoundary>
          <Suspense fallback={<Spinner />}>
            <UserSettings />
          </Suspense>
        </ErrorBoundary>
      </div>
    </Layout>
  )
}

export default AccountPage
