/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import React, { useState, Suspense } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { jsx } from '@emotion/react'
import {
  addDays,
  addMonths,
  format,
  formatISO,
  getDayOfYear,
  getMonth,
  startOfDay,
  startOfMonth,
  startOfWeek,
  startOfToday,
  subMonths,
} from 'date-fns'
import IconLeft from '@stiloso/icons/IconLeft.js'
import IconRight from '@stiloso/icons/IconRight.js'
import clickable from '@stiloso/styles/clickable.js'
import { useTranslation } from '@multilocale/react/index.js'
import Spinner from '@stiloso/components/Spinner.js'
import useAppointments from '@veterical/react/useAppointments.js'
import useClinic from '@veterical/react/useClinic.js'
import useClients from '@veterical/react/useClients.js'
import ErrorBoundary from '../components/ErrorBoundary.js'
import Layout from '../components/Layout.js'
import useLoggedInSession from '../session/useLoggedInSession.js'

export const paths = ['/clinics/:clinicId/calendar']

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]

const CalendarSection = () => {
  const navigate = useNavigate()
  const { clinicId } = useParams()
  const { t } = useTranslation()
  let { data: clinic } = useClinic(clinicId)
  let { data: appointments } = useAppointments({ clinicId: clinic._id })
  let [firstDayOfMonth, setFirstDayOfMonth] = useState(startOfMonth(new Date()))
  let month = months[getMonth(firstDayOfMonth)]

  appointments = appointments?.sort(
    (a, b) => new Date(a?.startTime) - new Date(b?.startTime),
  )

  let clientsIds = Object.keys(
    appointments?.reduce((clientsIds, appointment) => {
      if (appointment.clientId) {
        clientsIds[appointment.clientId] = true
      }

      return clientsIds
    }, {}),
  )

  // console.log(clientsIds)

  let { data: clients } = useClients({
    clinicId: clinic._id,
    _ids: clientsIds,
  })

  // console.log({ clients })

  let ids2clients = clients?.reduce((ids2clients, client) => {
    ids2clients[client._id] = client

    return ids2clients
  }, {})

  appointments = appointments?.map(appointment => ({
    ...appointment,
    client: ids2clients[appointment.clientId],
  }))

  // console.log({ appointments })

  let days2appointments = appointments.reduce(
    (days2appointments, appointment) => {
      try {
        let day = formatISO(startOfDay(appointment.startTime))

        if (!days2appointments[day]) {
          days2appointments[day] = []
        }

        days2appointments[day].push(appointment)
      } catch (error) {
        console.error(error)
      }

      return days2appointments
    },
    {},
  )

  // console.log(days2appointments)

  // console.log(firstDayOfMonth)

  let firstDayOfCalendarPage = startOfWeek(firstDayOfMonth, {
    weekStartsOn: 1,
  })

  // console.log(firstDayOfCalendarPage)

  let d = firstDayOfCalendarPage
  let days = []

  let currentMonth = getMonth(firstDayOfMonth)
  let currentDayOfYear = getDayOfYear(startOfToday())

  for (let i = 0; i < 7 * 6; i += 1) {
    let isCurrentMonth = getMonth(d) === currentMonth
    let isToday = getDayOfYear(d) === currentDayOfYear
    try {
      days.push({
        appointments: days2appointments[formatISO(d)],
        day: d,
        isCurrentMonth,
        isToday,
      })
    } catch (error) {
      console.error(error)
    }

    d = addDays(d, 1)
  }

  // console.log(days)

  // console.log(firstDayOfCalendarPage)
  // let today = startOfToday()

  let daysOfTheWeek = [
    t('Monday'),
    t('Tuesday'),
    t('Wednesday'),
    t('Thursday'),
    t('Friday'),
    t('Saturday'),
    t('Sunday'),
  ]

  let changeToPreviousMonth = () => {
    setFirstDayOfMonth(subMonths(firstDayOfMonth, 1))
  }

  let changeToNextMonth = () => {
    setFirstDayOfMonth(addMonths(firstDayOfMonth, 1))
  }

  // return <div>{formatISO(firstDay)}</div>
  return (
    <div css={{ height: '100%', width: '100%', padding: 16 }}>
      <div
        css={{
          fontSize: 32,
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          marginBottom: 16,
        }}
      >
        <div
          css={[clickable, { borderRadius: 24, width: 48, height: 48 }]}
          onClick={changeToPreviousMonth}
        >
          <IconLeft css={{ margin: 12 }} />
        </div>
        <div
          css={{
            flexGrow: 1,
            textAlign: 'center',
          }}
        >
          {t(month)}
        </div>
        <div
          css={[clickable, { borderRadius: 24, width: 48, height: 48 }]}
          onClick={changeToNextMonth}
        >
          <IconRight css={{ margin: 12 }} />
        </div>
      </div>
      <table css={{ height: '100%', width: '100%' }}>
        <thead>
          <tr>
            {daysOfTheWeek.map(dayOfTheWeek => (
              <th
                key={dayOfTheWeek}
                css={{ fontSize: 12, padding: 4, textTransform: 'uppercase' }}
              >
                {dayOfTheWeek}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {[0, 1, 2, 3, 4, 5].map(i => (
            <tr key={i}>
              {[0, 1, 2, 3, 4, 5, 6].map(j => {
                let { appointments, day, isCurrentMonth, isToday } =
                  days[i * 7 + j]
                let background = 'white'
                if (isToday) {
                  background = '#FFFFE7'
                } else if (!isCurrentMonth) {
                  background = 'var(--color-background)'
                }
                return (
                  <td
                    key={j}
                    css={[
                      clickable,
                      {
                        color: 'var(--color-base)',
                        background,
                        border: '1px solid var(--color-base)',
                        padding: 16,
                      },
                    ]}
                    onClick={() =>
                      navigate(
                        `/clinics/${clinicId}/appointments/new?date=${format(day, 'yyyy-MM-dd')}`,
                      )
                    }
                  >
                    <div css={{ display: 'flex', flexDirection: 'column' }}>
                      <div css={{ marginBottom: 4 }}>{day.getDate()}</div>
                      {appointments?.map(appointment => (
                        <div
                          key={appointment._id}
                          onClick={event => {
                            event.stopPropagation()
                            navigate(
                              `/clinics/${clinicId}/appointments/${appointment._id}`,
                            )
                          }}
                          css={[
                            clickable,
                            {
                              borderRadius: 16,
                              background: 'var(--color-primary)',
                              color: 'white',
                              fontSize: 12,
                              padding: '6px 8px',
                              marginTop: 4,
                              ':hover:not(:has(div:hover))': {
                                background: 'var(--color-primary-lighter)',
                              },
                            },
                          ]}
                        >
                          {format(appointment.startTime, 'HH:mm')}&nbsp;&nbsp;
                          {appointment.client?.name || ''}
                        </div>
                      ))}
                    </div>
                  </td>
                )
              })}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

const CalendarPage = () => {
  useLoggedInSession()
  const { t } = useTranslation()

  return (
    <Layout title={t('Calendar')}>
      <ErrorBoundary>
        <Suspense fallback={<Spinner />}>
          <CalendarSection />
        </Suspense>
      </ErrorBoundary>
    </Layout>
  )
}

export default CalendarPage
