/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
/* eslint-disable camelcase */
import React from 'react'
import { css, jsx } from '@emotion/react'
import { useTranslation } from '@multilocale/react/index.js'
import getClinics from '@veterical/client/getClinics.js'
import SignupPage from '@stiloso/pages/SignupPage.js'
import getCookie from '@monorepo/shared/getCookie.js'
import getNameFromEmail from '@monorepo/shared/getNameFromEmail.js'
import { useLocation, useNavigate } from 'react-router-dom'
import getBrowserLanguage from '@monorepo/shared/getBrowserLanguage.js'
import postSignup from '../postSignup.js'
import IllustrationVeterinary from '../components/IllustrationVeterinary.js'
import storeNewSession from '../session/storeNewSession.js'

export const paths = ['/signup']

function removeEmptyFields(object) {
  return Object.fromEntries(
    Object.entries(object).filter(([_, v]) => v != null),
  )
}

const SignupPageWrapper = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  let location = useLocation()
  let urlSearchParams = new URLSearchParams(location.search)
  let email = urlSearchParams.get('email')
  let password = urlSearchParams.get('password')
  let domain = urlSearchParams.get('domain')
  let gclidFromUrl = urlSearchParams.get('gclidFromUrl')
  let gclidFromCookie = getCookie('gclid')
  let referrer = getCookie('referrer')
  let utm_campaign = getCookie('utm_campaign')
  let utm_device = getCookie('utm_device')
  let utm_source = getCookie('utm_source')
  let utm_term = getCookie('utm_term')
  let gclid = gclidFromCookie || gclidFromUrl

  const signup = async ({ email, password }) => {
    let { firstName, lastName } = getNameFromEmail(email)
    let language = getBrowserLanguage()
    let signupResponse = await postSignup({
      email,
      password,
      firstName,
      lastName,
      language,
      gclid,
      referrer,
      utm_campaign,
      utm_device,
      utm_source,
      utm_term,
    })
    await storeNewSession(signupResponse)

    if (signupResponse.newOrganization) {
      navigate(
        '/setup-clinic?' +
          new URLSearchParams(removeEmptyFields({ domain })).toString(),
      )
    } else {
      const clinics = await getClinics()

      if (clinics.length === 1) {
        navigate(`/clinics/${clinics[0]._id}/calendar`)
      } else {
        navigate('/clinics')
      }
    }
  }

  let brand = (
    <div css={{ color: 'var(--color-primary)', fontSize: 36 }}>
      {t('Veterical')}
    </div>
  )

  return (
    <SignupPage
      brand={brand}
      signup={signup}
      email={email}
      password={password}
      illustration={<IllustrationVeterinary />}
    />
  )
}

export default SignupPageWrapper
